import * as React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ExtensionThreatSummaryApis } from "../../configurations/url-configs";
import {
  AttackVectorCategory,
  SourceOfReportPostPublish,
  SourceOfReportPrePublish,
  Keys,
  ProductType
} from "../../lib/enums/common-enums";
import { URLCreator } from "../../lib/url-creator/url-creator";
import Routes from "../../root/routes";
import { MaxItemsInPageComboBoxOption, SearchFilterDropdownOptions } from "../submit/dropdown-options";
import useComboBox, { IUseComboBoxInput } from "./useComboBox";
import useDatePicker, { IUseDatePicker } from "./useDatePicker";
import useDropdownMultiSelect, { IUseDropdownMultiSelectInput } from "./useDropdownMultiSelect";
import useInput, { IUseInput } from "./useInputValidation";

export interface IUseSearch {
  filterCriteria: IUseDropdownMultiSelectInput;
  search: () => void;
  searchField: IUseInput<React.FormEvent<HTMLInputElement>, string>;
  searchBackendUrl: string;
  fromDate: IUseDatePicker;
  toDate: IUseDatePicker;
  pageSize: IUseComboBoxInput;
  setPageNumber: React.Dispatch<React.SetStateAction<string>>
}

export interface IEnumType {
  [key: string]: string;
}

/**
 * Hook for search view.
 */
export function useSearch(product: ProductType): IUseSearch {
  const history = useHistory();
  const pageSize = useComboBox(MaxItemsInPageComboBoxOption[0].text, MaxItemsInPageComboBoxOption);
  const [pageNumber, setPageNumber] = React.useState<string>('1');
  const { search: filters } = useLocation();
  const filterCriteria = useDropdownMultiSelect(
    [],
    SearchFilterDropdownOptions,
    { key: Keys.NoneAttackVectorCategory, text: AttackVectorCategory.None },
    false,
  );
  const searchField = useInput(decodeURI(new URLSearchParams(filters).get("searchFilter") || ""));
  const [searchBackendUrl, setSearchBackendUrl] = React.useState<string>(
    new URLCreator(ExtensionThreatSummaryApis.GetFilteredSearchList).GetUrl(),
  );
  const fromDate = useDatePicker(new URLSearchParams(filters).get("fromDate") || null);
  const toDate = useDatePicker(new URLSearchParams(filters).get("toDate") || null);

  const search = () => {
    let newUrl = new URLCreator(`${Routes.Search}`);
    searchField.value && newUrl.AddParams("searchFilter", searchField.value);
    filterCriteria.selectedKeys.includes(Keys.PrePublishDropDownKey) && newUrl.AddParams("prePublished", "true");
    filterCriteria.selectedKeys.includes(Keys.PostPublishDropDownKey) && newUrl.AddParams("postPublished", "true");
    filterCriteria.selectedKeys.includes(Keys.MaliciousYesDropDownKey) && newUrl.AddParams("isMalicious", "true");
    filterCriteria.selectedKeys.includes(Keys.MaliciousNoDropDownKey) && newUrl.AddParams("isNotMalicious", "true");

    // chrome store url
    filterCriteria.selectedKeys.includes(Keys.ChromeStoreURLPresent) &&
      newUrl.AddParams("chromeStoreURLPresent", "true");
    filterCriteria.selectedKeys.includes(Keys.ChromeStoreURLAbsent) && newUrl.AddParams("chromeStoreURLAbsent", "true");
    filterCriteria.selectedKeys.includes(Keys.DaasURLPresent) && newUrl.AddParams("daasURLPresent", "true");
    filterCriteria.selectedKeys.includes(Keys.DaasURLAbsent) && newUrl.AddParams("daasURLAbsent", "true");

    const reportSourceFilter = [];

    SearchFilterOptionsSelector(filterCriteria.selectedKeys, SourceOfReportPrePublish, reportSourceFilter);
    SearchFilterOptionsSelector(filterCriteria.selectedKeys, SourceOfReportPostPublish, reportSourceFilter);
    reportSourceFilter.length && newUrl.AddParams("sourceOfReport", reportSourceFilter.join(","));

    fromDate.value &&
      newUrl.AddParams(
        "fromDate",
        `${fromDate.value.getFullYear()}-${fromDate.value.getMonth() + 1}-${fromDate.value.getDate()} 00:00:00`,
      );
    toDate.value &&
      newUrl.AddParams(
        "toDate",
        `${toDate.value.getFullYear()}-${toDate.value.getMonth() + 1}-${toDate.value.getDate()} 23:59:59`,
      );
    newUrl.AddParams("pageSize", pageSize.selectedItem);
    newUrl.AddParams("pageNumber", pageNumber);
    const newUrlParams = decodeURI(newUrl.GetSearch());
    newUrlParams && history.push(`/${product}/${Routes.Search}` + newUrlParams);
    setSearchBackendUrl(new URLCreator(ExtensionThreatSummaryApis.GetFilteredSearchList).GetUrl() + newUrlParams);
  };

  const SearchFilterOptionsSelector = (selectedKeys: string[], enumValues: IEnumType,
      result: string[], excludedKeys: string[] = []): string[] => {
    if (!selectedKeys?.length) {
        return result;
    }

    selectedKeys.forEach(item => {
        if (Object.keys(Keys).includes(item) && !result.includes(item)
            && enumValues[item] != undefined && !excludedKeys.includes(item)) {
            result.push(enumValues[item]);
        }
    });

    return result;
  }

  React.useEffect(() => { search() }, [pageNumber, filterCriteria.selectedKeys, fromDate.value, toDate.value, pageSize.selectedItem])

  return {
    filterCriteria,
    search,
    searchField,
    searchBackendUrl,
    fromDate,
    toDate,
    pageSize,
    setPageNumber
  };
}

