import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import * as React from "react";
import { NotAuthorizedReason } from "../../../configurations/configuration";
import Header from "../header/header";
import Navigation from "../navigation/navigation";
import { unAuthLayout } from "../unAuthLayout/unAuthLayout";
import "./layout.scss";
import { Roles } from "../../../lib/utils/auth-utils";

export interface ILayoutProps {
  children: React.ReactNode;
}
export default function Layout(props: ILayoutProps): React.ReactElement {
  const { instance, accounts } = useMsal();

  const AuthLayout = (): React.ReactElement => {
    return (
      <>
        <Header />
        <Navigation />
        <div className="content">{props.children}</div>
      </>
    );
  };

  const IsUserAuthorized = (roles: string[]): boolean => {
    if (!roles) {
      return false;
    }

    return roles.some(role => Object.values(Roles).includes(role as Roles))
  };

  return (
    <>
      <AuthenticatedTemplate>
        {IsUserAuthorized(instance.getAccountByHomeId(accounts[0]?.homeAccountId)?.idTokenClaims[`roles`])
          ? AuthLayout()
          : unAuthLayout(NotAuthorizedReason.InCorrectRole)}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>{unAuthLayout(NotAuthorizedReason.NotSignedIn)}</UnauthenticatedTemplate>
    </>
  );
}
