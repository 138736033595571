import { TextField } from "@fluentui/react";
import * as React from "react";
import localeStrings from "../../../../assets/localeStrings/english.json";
import { WikiLink } from "../../../configurations/configuration";
import { IExtensionMetaData } from "../../../lib/interfaces/common-interface";
import TextWithReadMore from "../../common-form-componnets/text-with-read-more/text-with-read-more";
import SectionTitleHelperText from "../section-title-helper-text/section-title-helper-text";
import "./extension-metadata-view.scss";

export default function ExtensionMetadataView(props: IExtensionMetaData): React.ReactElement {
  return (
    <div className={"extension-metadata"}>
      <div className="crxid-search-box">
        <p className="field-title">{localeStrings[`CRXID`]}</p>
        <TextField disabled={!!props.crxId} value={props.crxId} ariaLabel={localeStrings[`CRXID`]}/>
      </div>
      <SectionTitleHelperText
        title={localeStrings[`ExtensionMetadata_SectionTitle`]}
        helperText={localeStrings[`ExtensionMetadata_SectionHelpText`]}
        linkText={localeStrings[`LearnMoreText`]}
        link={WikiLink}
      />
      <div className="content">
        <div>
          <p className="field-title">{localeStrings[`ExtensionMetadata_BigId`]}</p>
          <p className="field-value">{props.bigId}</p>
        </div>
        <div>
          <p className="field-title">{localeStrings[`ExtensionMetadata_Name`]}</p>
          <p className="field-value">{props.extensionName}</p>
        </div>
        <div>
          <p className="field-title ">{localeStrings[`ExtensionMetadata_ExtensionVersion`]}</p>
          <p className="field-value">{props.extensionVersion}</p>
        </div>
        <div>
          <p className="field-title">{localeStrings[`ExtensionMetadata_SubmissionID`]}</p>
          <p className="field-value">{props.submissionId}</p>
        </div>
        <div>
          <p className="field-title">{localeStrings[`ExtensionMetadata_InstallCount`]}</p>
          <p className="field-value">{props.installCount}</p>
        </div>
        <div>
          <p className="field-title">{localeStrings[`ExtensionMetadata_ProductType`]}</p>
          <p className="field-value">{props.productType}</p>
        </div>
        <div className="description-section">
          <p className="field-title">{localeStrings[`ExtensionMetadata_Description`]}</p>
          <TextWithReadMore text={props.description} />
        </div>
      </div>
    </div>
  );
}
