import * as React from 'react';
import localeStrings from '../../../assets/localeStrings/english.json';
import Routes from '../../root/routes';
import useInput from '../hooks/useInputValidation';
import {
  KeyCodes,
  PrimaryButton,
  TextField
  } from '@fluentui/react';
import { getProduct, isValidGuid } from '../../lib/utils/util';
import { useHistory, useLocation } from 'react-router-dom';
import './productscan-information.scss';
import '../../html-constants/styles/html-styles';

export default function ProductScanInformation(): React.ReactElement {
  const history = useHistory();
  const productType = getProduct(useLocation().pathname);
  const search = () => {
    const productId = productIdSearchBox.value;
    const validationId = validationIdSearchBox.value;
    const route = validationId
      ? `/${productType}/${Routes.ScanInformation}/${productId}/${validationId}`
      : `/${productType}/${Routes.ScanInformation}/${productId}`;
    history.push(route);
  };
  const productIdSearchBox = useInput(
    "",
    true,
    localeStrings["ProductIdErrorText"],
    (value: string): boolean => value.length > 0 && isValidGuid(value));
  const validationIdSearchBox = useInput(
    "",
    false,
    localeStrings["ValidationIdErrorText"],
    (value: string): boolean => isValidGuid(value));

  return (
    <div>
      <h2 className="title">{localeStrings[`GetScanDetails`]}</h2>
      <hr />
      <div>
        <div className="report-outer-div">
          <h4>{localeStrings[`EnterProductId`]}</h4>
          <TextField
            onKeyPress={(e) => e.keyCode === KeyCodes.enter && search}
            placeholder={localeStrings[`ProductId`]}
            {...productIdSearchBox}
            iconProps={{ iconName: "search" }}
          />

          <h4>{localeStrings[`EnterValidationId`]}</h4>
          <TextField
            onKeyPress={(e) => e.keyCode === KeyCodes.enter}
            placeholder={localeStrings[`ValidationId`]}
            {...validationIdSearchBox}
            iconProps={{ iconName: "search" }}
          />

          <PrimaryButton 
            onClick={search}
            disabled={!productIdSearchBox.isValidInput || !validationIdSearchBox.isValidInput}>
            {localeStrings[`Search`]}
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
}
