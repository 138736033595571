import { Link } from "@fluentui/react";
import * as React from "react";
import "./section-title-helper-text.scss";

export interface ISectionTitleHelperText {
  title: string;
  helperText: string;
  link?: string;
  linkText?: string;
}
export default function SectionTitleHelperText(props: ISectionTitleHelperText): React.ReactElement {
  return (
    <div className={`section`}>
      <p className={"section-title"}>{props.title}</p>
      <p className={"section-helper-text"}>
        {props.helperText}
        {props.link && props.linkText && (
          <Link target={"_blank"} href={props.link}>
            {props.linkText}
          </Link>
        )}
      </p>
    </div>
  );
}
