import { AccountInfo } from "@azure/msal-browser";

export const enum AccessActions {
    ExtensionEditReports = "ExtensionEditReports",
    PluginsInitiateOnDemandScan = "PluginsInitiateOnDemandScan",
}

export enum Roles {
    Administrator = "Administrator",
    Analyst = "Analyst",
    Researcher = "Researcher",
    PluginResearcher = "PluginResearcher",
    PluginAnalyst = "PluginAnalyst",
}

export const accessRoles = {
    [AccessActions.ExtensionEditReports]: [Roles.Administrator, Roles.Researcher],
    [AccessActions.PluginsInitiateOnDemandScan]: [Roles.Administrator, Roles.PluginResearcher],
}

export const IsUserAuthorizedForAction = (accountInfo: AccountInfo, action: AccessActions): boolean => {
    const userRoles = accountInfo?.idTokenClaims[`roles`];
    
    if (!userRoles) {
      return false;
    }
    
    const allowedRoles = accessRoles[action];
  
    if (!allowedRoles) {
      return false;
    }
  
    return allowedRoles.some((role: Roles) => userRoles.includes(role));
};