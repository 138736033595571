import { Dropdown, TextField } from "@fluentui/react";
import * as React from "react";
import localeStrings from "../../../../assets/localeStrings/english.json";
import { WikiLink } from "../../../configurations/configuration";
import { ParagraphLines } from "../../../html-constants/styles/html-styles";
import { PublishType, SourceOfReportPostPublish, SourceOfReportPrePublish, Keys } from "../../../lib/enums/common-enums";
import { IThreatDetails } from "../../../lib/interfaces/common-interface";
import { ICollectFormData } from "../../hooks/useCollectFormData";
import useDropdown from "../../hooks/useDropdown";
import useInput from "../../hooks/useInputValidation";
import SectionTitleHelperText from "../../view/section-title-helper-text/section-title-helper-text";
import {
  PublishDropdownOptions,
  SourceOfReportPostPublishDropdownOptions,
  SourceOfReportPrePublishDropdownOptions,
} from "../dropdown-options";
import "./threat-details-submit.scss";

export interface IThreatDetailsSubmitProps extends ICollectFormData<Partial<IThreatDetails>> { }

export default function ThreatDetailsSubmit(props: IThreatDetailsSubmitProps): React.ReactElement {
  const sourceOfReport = useDropdown(
    props.initialData.sourceOfReport,
    props.initialData.publishType === PublishType.PostPublish
      ? SourceOfReportPostPublishDropdownOptions
      : SourceOfReportPrePublishDropdownOptions,
    true,
  );
  const symptomsOrBehavior = useInput<React.FormEvent<HTMLInputElement>, string>(
    props.initialData.symptomsOrBehavior,
    true,
  );
  const publish = useDropdown(props.initialData.publishType, PublishDropdownOptions, true);

  React.useEffect(() => {
    if (
      sourceOfReport.isValidInput &&
      symptomsOrBehavior.isValidInput &&
      publish.isValidInput
    ) {
      props.setData({
        publishType: publish.selectedItem as PublishType,
        sourceOfReport: sourceOfReport.selectedItem as SourceOfReportPrePublish | SourceOfReportPostPublish,
        symptomsOrBehavior: symptomsOrBehavior.value,
      });
    } else {
      props.setData(null);
    }
  }, [sourceOfReport.selectedKey, symptomsOrBehavior.value, publish.selectedKey]);

  React.useEffect(
    () =>
      sourceOfReport.updateOptions(
        publish.selectedKey === Keys.PrePublishDropDownKey
          ? SourceOfReportPrePublishDropdownOptions
          : SourceOfReportPostPublishDropdownOptions,
      ),
    [publish.selectedKey],
  );

  return (
    <div className={"threat-details"}>
      <SectionTitleHelperText
        title={localeStrings[`ThreatDetails_SectionTitle`]}
        helperText={localeStrings[`ThreatDetails_SectionHelpText`]}
        linkText={localeStrings[`LearnMoreText`]}
        link={WikiLink}
      />
      <div className="content">
        <Dropdown
          placeholder={localeStrings[`SelectAnOption`]}
          label={localeStrings[`ThreatDetails_PublishState`]}
          options={PublishDropdownOptions}
          {...publish}
        />
        <Dropdown
          placeholder={localeStrings[`SelectAnOption`]}
          label={localeStrings[`ThreatDetails_SourceOfReport`]}
          {...sourceOfReport}
        />
        <TextField
          label={localeStrings[`ThreatDetails_SymptomsOrBehavior`]}
          className="report-symptoms"
          rows={ParagraphLines}
          multiline
          {...symptomsOrBehavior}
        />
      </div>
    </div>
  );
}
