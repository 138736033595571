import {
  ComboBox,
  CommandButton,
  DatePicker,
  DetailsList,
  DetailsListLayoutMode,
  Dropdown,
  PrimaryButton,
  SearchBox,
  SelectionMode,
  Spinner,
  SpinnerSize,
} from "@fluentui/react";
import _ from "lodash";
import * as React from "react";
import { useHistory, useLocation } from "react-router-dom";
import localeStrings from "../../../../assets/localeStrings/english.json";
import { ExtensionThreatAnalysisLogin } from "../../../configurations/configuration";
import { IThreatAnalysisDatabaseRecord, IThreatAnalysisDatabaseSearchResult } from "../../../lib/interfaces/common-interface";
import { ComboBoxOptionGenerator, getProduct } from "../../../lib/utils/util";
import Routes from "../../../root/routes";
import GenericErrorScreen from "../../error-screens/generic-error-screen";
import { useAxiosGet } from "../../hooks/useAxiosGet";
import useComboBox from "../../hooks/useComboBox";
import { useSaveJson2Csv } from "../../hooks/useJson2Csv";
import { useSearch } from "../../hooks/useSearch";
import { SearchColumns } from "./layout-config";
import SearchNoResults from "./search-no-results/search-no-results";
import "./search-view.scss";

export default function SearchView(): React.ReactElement {
  const productType = getProduct(useLocation().pathname);
  const { filterCriteria, search, searchField, searchBackendUrl, fromDate, toDate, pageSize, setPageNumber } =
    useSearch(productType);
  const pageNumberCombobox = useComboBox(ComboBoxOptionGenerator(1)[0].text, ComboBoxOptionGenerator(1))
  const { saveJsonAsCsv, error: errorCSVLoading, loading: downloadCSVLoading } = useSaveJson2Csv();
  const { result: homeResult, loading: homeLoading, error: homeError } = useAxiosGet<
    IThreatAnalysisDatabaseSearchResult
  >(searchBackendUrl, ExtensionThreatAnalysisLogin);
  const [isAdvancedSearchClicked, setIsAdvancedSearchClicked] = React.useState<boolean>((fromDate?.value || toDate?.value) ? true : false);

  React.useEffect(() => setPageNumber(pageNumberCombobox.selectedItem), [pageNumberCombobox.selectedItem]);

  if (homeError || errorCSVLoading) {
    return <GenericErrorScreen error={homeError || errorCSVLoading} />;
  }
  React.useEffect(() => {
    if (homeResult?.totalResults) {
      const maxPage = Math.ceil(homeResult.totalResults / parseInt(pageSize.selectedItem));
      pageNumberCombobox.setOptions(ComboBoxOptionGenerator(maxPage));
    }
  }, [homeResult?.totalResults, pageSize.selectedItem])
  const history = useHistory();

  return (
    <div className="search-view">
      <div className="title-outer">
        <h2 className="title">
          {localeStrings['Search']}
        </h2>
        {homeLoading && <Spinner size={SpinnerSize.medium} />}
        {!homeLoading &&
          <h2 className="title">
            {localeStrings['SearchPageTitle']}
            {homeResult?.totalResults && homeResult.totalResults}
          </h2>
        }
      </div>
      <hr />
      <div className="search-command-bar">
        <div className="align-vertical">
          <SearchBox
            className="search-box"
            placeholder="Search"
            {...searchField}
            onSearch={search}
            disabled={homeLoading}
          />
          <Dropdown
            title={localeStrings[`AdvanceSearchFilters`]}
            className="advance-filters"
            placeholder={localeStrings[`AdvanceSearchFilters`]}
            multiSelect
            {...filterCriteria}
            disabled={homeLoading || downloadCSVLoading}
          />
          <CommandButton
            iconProps={{ iconName: "FilterSettings" }}
            text={localeStrings[`SearchByDate`]}
            onClick={() => setIsAdvancedSearchClicked(!isAdvancedSearchClicked)}
            disabled={homeLoading || downloadCSVLoading}
          />
          {isAdvancedSearchClicked ? (
            <div className="align-vertical advanced-search">
              <DatePicker
                placeholder={localeStrings[`FromDate`]}
                {...fromDate}
                disabled={homeLoading || downloadCSVLoading}
                ariaLabel={localeStrings[`FromDatePicker`]}
              />
              <DatePicker
                placeholder={localeStrings[`ToDate`]}
                {...toDate}
                disabled={homeLoading || downloadCSVLoading}
                ariaLabel={localeStrings[`ToDatePicker`]}
              />
              <PrimaryButton
                onClick={search}
                text={localeStrings[`Search`]}
                disabled={homeLoading || downloadCSVLoading}
              />
            </div>
          ) : null}
        </div>
        <div className="align-vertical">
          <CommandButton
            iconProps={{ iconName: "Download" }}
            text={localeStrings[`ExportCSV`]}
            onClick={() => saveJsonAsCsv(homeResult?.data, "results.csv")}
            disabled={homeLoading || downloadCSVLoading || homeResult.data.length == 0}
          />

          <p className="text">{localeStrings[`Display`]}</p>
          <ComboBox title={localeStrings[`Display`]} className="item-combo" {...pageSize} />
          {homeLoading && <Spinner size={SpinnerSize.medium} />}
          {!homeLoading && <>
            <p className="text">{localeStrings[`Page`]}</p>
            <ComboBox title={localeStrings[`Page`]} className="item-combo" {...pageNumberCombobox} />
          </>
          }
        </div>
      </div>

      <hr />
      {homeLoading || downloadCSVLoading ? (
        <Spinner size={SpinnerSize.large} />
      ) : homeResult?.totalResults === 0 ? (
        <SearchNoResults />
      ) : (
        <DetailsList
          items={homeResult.data}
          columns={SearchColumns}
          layoutMode={DetailsListLayoutMode.justified}
          selectionPreservedOnEmptyClick={true}
          selectionMode={SelectionMode.none}
          onItemInvoked={(item: IThreatAnalysisDatabaseRecord) => history.push(`/${productType}/${Routes.ThreatAnalysisRecordSearch}/${item.id}`)}
        />
      )}
    </div>
  );
}
