import * as React from "react";

export const SearchNoResultsIcon = (): React.ReactElement => {
  return (
    <svg width="118" height="118" viewBox="0 0 118 118" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M117.188 117.188H0L58.5938 0L117.188 117.188ZM12.6343 109.375H104.553L58.5938 17.4561L12.6343 109.375ZM62.5 46.875V85.9375H54.6875V46.875H62.5ZM54.6875 93.75H62.5V101.562H54.6875V93.75Z"
        fill="#C8C6C4"
      />
    </svg>
  );
};
