import * as React from "react";
import { useHistory } from "react-router-dom";
import localeStrings from "../../../assets/localeStrings/english.json";
import Routes from "../../root/routes";
import ActionCards from "./action-cards/action-cards";
import "./home.scss";
import { ProductType } from "../../lib/enums/common-enums";

export default function ExtensionHome(): React.ReactElement {
  const history = useHistory();

  return (
    <div className="home">
      <h2 className="title">{localeStrings[`Extension_Home_Title`]}</h2>
      <div className="action-cards">
        <ActionCards
          title={localeStrings[`ActionCardCreateThreatAnalysisSubmissionId`]}
          subTitle={localeStrings[`ActionCardCreateSubTitle`]}
          icon={"Add"}
          actionText={localeStrings[`ActionCardCreateSubText`]}
          onClick={() => history.push(`${Routes.ThreatAnalysisRecordCreate}`)}
          href={`${window.location.origin}/${Routes.ThreatAnalysisRecordCreate}`}
        />
        <ActionCards
          title={localeStrings[`ActionCardViewThreatAnalysis`]}
          subTitle={localeStrings[`ActionCardViewSubTitle`]}
          icon={"Search"}
          actionText={localeStrings[`ActionCardViewSubText`]}
          onClick={() => history.push(`${Routes.ThreatAnalysisRecordSearch}`)}
          href={`${window.location.origin}/${Routes.ThreatAnalysisRecordSearch}`}
        />
        <ActionCards
          title={localeStrings[`ActionCardViewFullReport`]}
          subTitle={localeStrings[`ActionCardViewFullReportSubTitle`]}
          icon={"Search"}
          actionText={localeStrings[`ActionCardViewSubText`]}
          onClick={() => history.push(`${Routes.ScanReport}`)}
          href={`${window.location.origin}/${Routes.ScanReport}`}
        />
        <ActionCards
          title={localeStrings[`ActionCardSearch`]}
          subTitle={localeStrings[`ActionCardSearchSubTitle`]}
          icon={"Search"}
          actionText={localeStrings[`ActionCardViewSubText`]}
          onClick={() => history.push(`${Routes.Search}`)}
          href={`${window.location.origin}/${Routes.Search}`}
        />
        <ActionCards
          title={localeStrings[`ActionCardViewProductInformation`].replace('Product', ProductType.Extension)}
          subTitle={localeStrings[`ActionCardViewProductInformationSubTitle`].replace('Product', ProductType.Extension)}
          icon={"Info"}
          actionText={localeStrings[`ActionCardSearch`]}
          onClick={() => history.push(`${Routes.Information}`)}
          href={`${window.location.origin}/${Routes.Information}`}
        />
        <ActionCards
          title={localeStrings[`ActionCardViewRuleReport`]}
          subTitle={localeStrings[`ActionCardViewRuleReportSubTitle`]}
          icon={"Search"}
          actionText={localeStrings[`ActionCardViewSubText`]}
          onClick={() => history.push(`${Routes.Rules}`)}
          href={`${window.location.origin}/${Routes.Rules}`}
        />
      </div>
    </div>
  );
}
