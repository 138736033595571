export const guidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

export const versionRegex = /^\d+\.\d+\.\d+$/;

export const authConfig = {
    development: {
        "clientId": "2a6912eb-2a14-44c3-bb65-0f4b6f53c71a",
        "scope": ["api://2a6912eb-2a14-44c3-bb65-0f4b6f53c71a/Extension.ThreatAnalysis"]
    },
    test: {
        "clientId": "2a6912eb-2a14-44c3-bb65-0f4b6f53c71a",
        "scope": ["api://2a6912eb-2a14-44c3-bb65-0f4b6f53c71a/Extension.ThreatAnalysis"]
    },
    production: {
        "clientId": "92934af5-e322-4f86-a684-e1a0b5c032f4",
        "scope": ["api://92934af5-e322-4f86-a684-e1a0b5c032f4/Extension.ThreatAnalysis"]
    }
};

export const hostURLs = {
    development: "localhost",
    test: "extensionthreatanalysis-test.azurewebsites.net",
    testTrafficManager: "threatanalysis-test.edgeextsecurity.trafficmanager.net"
}