import Axios, { AxiosError } from "axios";
import * as React from "react";
import { IScopesObj } from "../../lib/interfaces/common-interface";
import { GetBearerHeader } from "../../lib/utils/util";
import { useAccessToken } from "./useAccessToken";

export interface IAxiosGet<T> {
  result: T;
  loading: boolean;
  error: AxiosError<any>;
}

export function useAxiosGet<T>(url: string, scopesObj: IScopesObj = null): IAxiosGet<T> {
  const [result, setResult] = React.useState<T>(null);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [error, setError] = React.useState<AxiosError<any>>(null);
  const { accessToken } = useAccessToken(scopesObj);
  
  const axiosWrapper = (accessToken) => {
    setLoading(true);
    Axios.get<T>(url, {
      headers: GetBearerHeader(accessToken),
    })
      .then((response) => {
        setResult(response.data);
      })
      .catch((err) => setError(err))
      .finally(() => setLoading(false));
  };

  React.useEffect(() => {
    if (accessToken !== null) {
      axiosWrapper(accessToken);
    }
  }, [accessToken, url]);

  return {
    result,
    loading,
    error,
  };
}
