import * as React from 'react';
import localeStrings from '../../../assets/localeStrings/english.json';
import Routes from '../../root/routes';
import useDropdown from '../hooks/useDropdown';
import useInput from '../hooks/useInputValidation';
import {
  Dropdown,
  KeyCodes,
  PrimaryButton,
  TextField
  } from '@fluentui/react';
import { getProduct, isValidGuid, SubmissionIdValidation } from '../../lib/utils/util';
import { Keys, ProductType, ScannerKeys } from '../../lib/enums/common-enums';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import './report.scss';
import {
  ListenersDropdown,
  PluginListenersDropdown,
  PluginScanTypeDropdownOptions,
  ScanTypeDropdownOptions,
} from "../submit/dropdown-options";

interface IViewPageParams {
  scanId?: string | null;
}

export default function Report(): React.ReactElement {
  const productType = getProduct(useLocation().pathname);
  const { scanId } = useParams<IViewPageParams>();
  const history = useHistory();
  const location = useLocation();
  const searchBox = useInput(
    scanId ? (scanId) : (""),
    true,
    localeStrings["ScanIdErrorText"],
    (value: string): boolean => value.length > 0 && (isValidGuid(value) || SubmissionIdValidation(value)));
  const search = () => {
    history.push(
      `/${productType}/${Routes.ScanReport}/${scanTypeDropDownOptions.selectedItem}/${searchBox.value}/${listenerDropDownOptions.selectedKey}`,
    );
  }

  const listenerDropDownOptions = useDropdown(
    location.pathname.includes(ProductType.Extension) ? ScannerKeys.AggregateReport : ScannerKeys.StaticAnalysisManifestScan,
    location.pathname.includes(ProductType.Extension) ? ListenersDropdown : PluginListenersDropdown,
    true,
  );
  
  const scanTypeDropDownOptions = useDropdown(
    Keys.PrePublishScanType,
    location.pathname.includes(ProductType.Extension) ? ScanTypeDropdownOptions : PluginScanTypeDropdownOptions,
    true,
  );

  return (
      <div>
      <h2 className="title">{localeStrings[`SearchScanId`]}</h2>
      <hr />
      <div>
          <div className="report-outer-div">
          <h4>{localeStrings[`ScanId`]}</h4>
          <TextField
            onKeyPress={(e) => e.keyCode === KeyCodes.enter && search}
            placeholder={localeStrings[`ScanId`]}
            {...searchBox}
            iconProps={{ iconName: "search" }}
          />
          <h4>{localeStrings[`ScanType`]}</h4>
          <Dropdown {...scanTypeDropDownOptions} />
          <h4>{localeStrings[`Scanner`]}</h4>
          <Dropdown className="dropdown-scanner" {...listenerDropDownOptions} />
          <PrimaryButton onClick={search} disabled={!searchBox.isValidInput}>
            {localeStrings[`Search`]}
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
}
