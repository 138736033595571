import { IDropdownOption } from "@fluentui/react";
import * as React from "react";
import localeStrings from "../../../assets/localeStrings/english.json";

export interface IUseDropdownMultiSelectInput {
  isValidInput: boolean;
  onChange: (e: React.FormEvent<HTMLDivElement>, newValue: IDropdownOption<string>) => void;
  selectedItems?: string[];
  selectedKeys?: string[];
  errorMessage: string;
  required: boolean;
  options: IDropdownOption[];
  updateOptions: (v: IDropdownOption[]) => void;
  onRenderOption: (option: IDropdownOption) => void;
}

const toolTipStyle: React.CSSProperties = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
}

export default function useDropdownMultiSelect(
  defaultSelectedItem: string[],
  options: IDropdownOption[],
  resetOption: IDropdownOption = null,
  required: boolean,
  errorHelpText = localeStrings[`GenericDropDownErrorText`],
): IUseDropdownMultiSelectInput {
  const [selectedKeys, setSelectedKeys] = React.useState<string[]>(
    Array.isArray(defaultSelectedItem) ? defaultSelectedItem : [],
  );
  const [selectedItems, setSelectedItems] = React.useState<string[]>();
  const [errorMessage, setErrorMessage] = React.useState<string>(errorHelpText);
  const [isValidInput, setIsValidInput] = React.useState<boolean>(false);
  const [possibleOptions, setPossibleOptions] = React.useState<IDropdownOption[]>(options);

  const onChange = (e: React.FormEvent<HTMLDivElement>, newValue: IDropdownOption) => {
    let newSelectedKeys = [...(selectedKeys as string[])];
    let newSelectedItems = [...(selectedItems as string[])];

    if (resetOption && newValue.key === resetOption.key) {
      newSelectedKeys = [resetOption.key as string];
      newSelectedItems = [resetOption.text];
    } else if (resetOption && selectedKeys.includes(resetOption.key as string)) {
      newSelectedKeys = [newValue.key as string];
      newSelectedItems = [newValue.text];
    } else {
      if (newValue.selected) {
        newSelectedKeys.push(newValue.key as string);
        newSelectedItems.push(newValue.text);
      } else {
        newSelectedKeys.splice(newSelectedKeys.indexOf(newValue.key as string), 1);
        newSelectedItems.splice(newSelectedItems.indexOf(newValue.text), 1);
      }
    }
    setSelectedKeys(newSelectedKeys);
    setSelectedItems(newSelectedItems);
    };

  const onRenderOption = (option: IDropdownOption): JSX.Element => {
      return <div title={option.text} style={toolTipStyle}>{option.text}</div>
  };

  React.useEffect(() => {
    setIsValidInput(selectedKeys.length > 0);
    setErrorMessage(selectedKeys.length === 0 && required ? errorHelpText : "");
  }, [selectedKeys]);

  React.useEffect(() => {
    if (!possibleOptions) {
      return
    }
    const items = possibleOptions.filter((ele) => defaultSelectedItem?.includes(ele.text));
    setSelectedItems(items.map((ele) => ele.text) as string[]);
    setSelectedKeys(items.map((ele) => ele.key) as string[]);
    setIsValidInput(items ? true : false);
    setErrorMessage(!items && required ? errorHelpText : "");
  }, []);

  React.useEffect(() => {
    setErrorMessage(selectedItems?.length === 0 && required ? errorHelpText : "");
  }, [required]);

  return {
    errorMessage,
    isValidInput,
    onChange,
    options: possibleOptions,
    required,
    selectedItems,
    selectedKeys,
    updateOptions: (v) => setPossibleOptions(v),
    onRenderOption
  };
}
