import { CommandButton, Link } from "@fluentui/react";
import * as React from "react";
import "./action-card.scss";

export interface IActionCardsProps {
  title: string;
  subTitle: string;
  icon: string;
  actionText: string;
  onClick: () => void;
  href: string;
}
export default function ActionCards(props: IActionCardsProps): React.ReactElement {
  return (
    <Link href={props.href} onClick={(event) => { event.preventDefault(); props.onClick(); }}>
      <div className="action-card">
        <p>{props.title}</p>
        <p>{props.subTitle}</p>
        <CommandButton
          className="action-text"
          iconProps={{ iconName: props.icon }}
          text={props.actionText}
        />
      </div>
    </Link>
  );
}
