import axios, { AxiosError } from "axios";
import * as React from "react";
import { IScopesObj } from "../../lib/interfaces/common-interface";
import { GetBearerHeader } from "../../lib/utils/util";
import { useAccessToken } from "./useAccessToken";

export enum ApiCallStatus {
  None,
  Loading,
  Failed,
  Succeeded,
}
export interface IAxiosPost<T> {
  result: T;
  apiCallStatus: ApiCallStatus;
  error: AxiosError<any>;
  postFunc: (body: any) => void;
}

export default function useAxiosPost<T>(url: string, scopesObj: IScopesObj = null): IAxiosPost<T> {
  const [result, setResult] = React.useState<T>(null);
  const [apiCallStatus, setApiCallStatus] = React.useState<ApiCallStatus>(ApiCallStatus.None);
  const [error, setError] = React.useState<AxiosError<any>>(null);
  const { accessToken } = useAccessToken(scopesObj);

  const postFunc = (body: any) => {
    setApiCallStatus(ApiCallStatus.Loading);
    axios
      .post(url, body, { headers: GetBearerHeader(accessToken) })
      .then((res) => {
        setResult(res.data);
        setApiCallStatus(ApiCallStatus.Succeeded);
      })
      .catch((err) => {
        setError(err);
        setApiCallStatus(ApiCallStatus.Failed);
      });
  };

  React.useEffect(() => {}, []);

  return {
    apiCallStatus,
    error,
    postFunc,
    result,
  };
}
