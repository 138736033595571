import { DefaultButton, Link, Spinner, SpinnerSize } from "@fluentui/react";
import * as React from "react";
import { useHistory } from "react-router";
import localeStrings from "../../../../assets/localeStrings/english.json";
import { ExtensionThreatAnalysisLogin } from "../../../configurations/configuration";
import { ExtensionThreatSummaryApis } from "../../../configurations/url-configs";
import { ProductType } from "../../../lib/enums/common-enums";
import { IExtensionInformation, IExtensionSasUrl } from "../../../lib/interfaces/common-interface";
import { URLCreator } from "../../../lib/url-creator/url-creator";
import Routes from "../../../root/routes";
import GenericErrorScreen from "../../error-screens/generic-error-screen";
import { useAxiosGet } from "../../hooks/useAxiosGet";
import "./extension-info-by-submission-id.scss";

interface ExtensionInformationByCrxIdProps {
    submissionId: string
    title: string
    subtitle?: string
}

export function ExtensionInformationBySubmissionId(props: ExtensionInformationByCrxIdProps): React.ReactElement {
    const history = useHistory();
    const { result, loading, error } = useAxiosGet<IExtensionInformation>(
        new URLCreator(ExtensionThreatSummaryApis.GetExtensionInformationBySubmissionId)
            .AddRouteParam(props.submissionId)
            .GetUrl(),
        ExtensionThreatAnalysisLogin,
    );

    const { result: sasResult, loading: sasLoading, error: sasError } = useAxiosGet<IExtensionSasUrl>(
        new URLCreator(ExtensionThreatSummaryApis.GetSasUrlBySubmissionId)
            .AddRouteParam(props.submissionId)
            .GetUrl(),
        ExtensionThreatAnalysisLogin,
    );

    if (error || sasError) {
        return <GenericErrorScreen error={error || sasError} />;
    }

    if (loading || sasLoading) {
        return <Spinner size={SpinnerSize.medium} />
    }
    return (
        <div className="submission-info-outer">
            <h3 title={props.subtitle}>{props.title}</h3>
            <div className="submission-info">
                <h4 className="cell">Submission Id</h4>
                <p className="cell value">{result.submissionId}</p>
                <h4 className="cell">Title</h4>
                <p className="cell value">{result.title}</p>
                <h4 className="cell">Crx Id</h4>
                <p className="cell value">{result.crxId}</p>
                <h4 className="cell">Big Id</h4>
                <p className="cell value">{result.bigId}</p >
            </div >
            <div className="download-view">
                <DefaultButton text={localeStrings[`DownloadPackage`]} onClick={() => window.location.href = sasResult.sasUrl} allowDisabledFocus />
                <DefaultButton text={localeStrings[`Report`]} onClick={() => history.push(`/${ProductType.Extension}/${Routes.ScanReport}/PrepublishStaticAnalysis/${result.submissionId}/AggregateReport`)} />
            </div>
        </div>
    );
}
