import { ActionType, IdentifierType, ResourceType } from "../lib/enums/common-enums";
/**
 * List of all the routes on front end page
 */
export default class Routes {
  public static Home = `${ResourceType.Home}`;
  public static ScanReport = `${ResourceType.ScanReport}`;
  public static Search = `${ResourceType.Search}`;
  public static Rules = `${ResourceType.Rules}`;

  // Threat analysis record routes
  public static ThreatAnalysisRecordSearch = `${ResourceType.ThreatAnalysisRecord}/${ActionType.Search}`;
  public static ThreatAnalysisRecordEdit = `${ResourceType.ThreatAnalysisRecord}/${ActionType.Edit}`;
  public static ThreatAnalysisRecordCreate = `${ResourceType.ThreatAnalysisRecord}/${ActionType.Create}`;

  // Information routes
  public static Information = `${ResourceType.Information}`;
  public static InformationBySubmissionId = `${ResourceType.Information}/${ActionType.Search}/${IdentifierType.SubmissionId}`;
  public static InformationByCrxId = `${ResourceType.Information}/${ActionType.Search}/${IdentifierType.CrxId}`;
  public static ScanInformation = `${ResourceType.ScanInformation}`;

  // On-demand scan routes
  public static InitiateScan = `${ResourceType.Scan}/${ActionType.Initiate}`;
  public static ScanStatus = `${ResourceType.Scan}/${ActionType.Status}`;
}
