import { ChoiceGroup, KeyCodes, PrimaryButton, TextField } from "@fluentui/react";
import * as React from "react";
import { useHistory, useLocation } from "react-router-dom";
import localeStrings from "../../../../assets/localeStrings/english.json";
import { GuidValidation, getProduct } from "../../../lib/utils/util";
import Routes from "../../../root/routes";
import useInput from "../../hooks/useInputValidation";
import "./view-report.scss";

export function ViewReport(): React.ReactElement {
  const history = useHistory();
  const searchBox = useInput(
    "",
    true,
    localeStrings[`ReportIdErrorText`],
    GuidValidation,
  );
  const productType = getProduct(useLocation().pathname);
  const search = () =>
    history.push(
      `/${productType}/${Routes.ThreatAnalysisRecordSearch}/${searchBox.value}`,
    );

  return (
    <div>
      <h2 className="title">
        {localeStrings[`SearchReportId`]}
      </h2>
      <hr />
      <div className="search-outer-div">
        <div className="search">
          <TextField
            onKeyPress={(e) => e.keyCode === KeyCodes.enter && search}
            placeholder={localeStrings[`EnterToSearch`]}
            className="search-box"
            {...searchBox}
            iconProps={{ iconName: "search" }}
          />
          <PrimaryButton onClick={search} disabled={!searchBox.isValidInput} className="search-button">
            {localeStrings[`Search`]}
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
}
