import * as React from "react";
import localeStrings from "../../../assets/localeStrings/english.json";

export interface IUseInput<T, V> {
  isValidInput: boolean;
  onChange: (e: T, newValue: V) => void;
  value: V;
  defaultSelectedKey?: V;
  errorMessage: string;
  required: boolean;
  autoComplete: string;
  setRequired: (v: boolean) => void;
  updateValidator: (v: (value: V) => boolean) => void;
}

export default function useInput<T = React.FormEvent<HTMLInputElement>, V = string>(
  defaultValue: V,
  isRequired: boolean = false,
  errorHelpText = localeStrings[`GenericInputErrorText`],
  inputValidator: (value: V) => boolean = (inputValue: V) => !!inputValue,
): IUseInput<T, V> {
  const [value, setValue] = React.useState<V>(defaultValue);
  const [required, setRequired] = React.useState<boolean>(isRequired);
  const [validator, setValidator] = React.useState<(value:V) => boolean>(() => inputValidator);
  const [isValidInput, setIsValidInput] = React.useState<boolean>(required ? validator(defaultValue) : true);
  const [errorMessage, setErrorMessage] = React.useState<string>(required ? errorHelpText : "");
  React.useEffect(() => setErrorMessage(errorHelpText), [errorHelpText]);

  const onChange = (e: T, newValue: V) => {
    setValue(newValue);
    setIsValidInput(required ? validator(newValue) : true);
  };

  React.useEffect(() => setErrorMessage(!isValidInput && required ? errorHelpText : ""), [isValidInput]);

  React.useEffect(() => setIsValidInput(required ? validator(value) : true), [required, validator]);

  return {
    autoComplete: "off",
    defaultSelectedKey: defaultValue,
    errorMessage,
    isValidInput,
    onChange,
    required,
    value,
    setRequired: (v) => setRequired(v),
    updateValidator: (v) => setValidator(() => v)
  };
}
