import * as React from 'react';
import GenericErrorScreen from '../../error-screens/generic-error-screen';
import localeStrings from '../../../../assets/localeStrings/english.json';
import Routes from '../../../root/routes';
import { ExtensionThreatAnalysisLogin } from '../../../configurations/configuration';
import { ExtensionThreatSummaryApis } from '../../../configurations/url-configs';
import { IScanIdColumnView } from '../../../lib/interfaces/common-interface';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Publishers } from '../../../lib/enums/common-enums';
import { URLCreator } from '../../../lib/url-creator/url-creator';
import { useAxiosGet } from '../../hooks/useAxiosGet';
import './view-scanIds.scss';
import {
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  SelectionMode,
  Spinner,
  SpinnerSize,
} from "@fluentui/react";
import { getProduct } from '../../../lib/utils/util';

interface IViewPageParams {
  productId: string;
  validationId?: string | null;
}

export default function ViewScanIds(): React.ReactElement {
  const productType = getProduct(useLocation().pathname);
  const { productId, validationId } = useParams<IViewPageParams>();
  const { result, loading, error } = useAxiosGet<IScanIdColumnView[]>(
    new URLCreator(ExtensionThreatSummaryApis.GetScanIdByValidationIdAndProductId)
      .AddParams("productId", productId)
      .AddParams("validationId", validationId === undefined ? "" : validationId)
      .GetUrl(),
    ExtensionThreatAnalysisLogin,
  );

  const renderScanIds = (item: IScanIdColumnView): React.ReactNode => {
    return item.scanIds.map((scanId: string) => 
      <div style={{paddingTop: "8px", paddingBottom: "8px"}}>
        <Link to = {`/${productType}/${Routes.ScanReport}/${scanId}`} style={{textDecoration: 'none'}}>{scanId}</Link>
      </div>);
  }

  const renderPayloadRegistryIds = (item: IScanIdColumnView): React.ReactNode => {
    return item.payloadRegistryIds.map((payloadRegistryId: string) => React.createElement(
      "div",
      { key: payloadRegistryId, style: {paddingTop: "8px", paddingBottom: "8px"}},
      payloadRegistryId));
  }
  
  const renderPublishers = (item: IScanIdColumnView): React.ReactNode => {
    return item.publishers.map((publisher: Publishers) => React.createElement(
      "div",
      { key: publisher, style: {paddingTop: "8px", paddingBottom: "8px"}},
      publisher));
  }
  const renderVersion = (item: IScanIdColumnView): React.ReactNode => {
    return item.version.map((version: string) => React.createElement(
      "div",
      { key: version, style: {paddingTop: "8px", paddingBottom: "8px"}},
      version));
  }
  
  const renderValidationId = (item: IScanIdColumnView): React.ReactNode => {
    return React.createElement(
      "div",
      { key: item.validationId, style: {display: "flex", alignItems: "center", height: "100%"}},
      item.validationId);
  }

  const renderScanInitiator = (item: IScanIdColumnView): React.ReactNode => {
    return React.createElement(
      "div",
      { key: item.validationId, style: {display: "flex", alignItems: "center", height: "100%"}},
      item.scanInitiator);
  }
  
  const renderDateTime = (item: IScanIdColumnView): React.ReactNode => {
    return item.createdDateTime.map((dateTime: Date) => React.createElement(
      "div",
      { key: dateTime.toString(), style: {paddingTop: "8px", paddingBottom: "8px"}},
      `${dateTime.toLocaleString()}`));
  }

  // Update locale strings. 
  const columns: IColumn[] = [
    { key: "validationId", name: localeStrings["ValidationId"], fieldName: "validationId", minWidth: 280, isResizable: true, onRender: renderValidationId },
    { key: "payloadRegistryIds", name: localeStrings["PayloadRegistryId"], fieldName: "payloadRegistryIds", minWidth: 280, isResizable: true, onRender: renderPayloadRegistryIds },
    { key: "scanIds", name: localeStrings["ScanId"], fieldName: "scanIds", minWidth: 280, isResizable: true, onRender: renderScanIds },
    { key: "publishers", name: localeStrings["Publishers"], fieldName: "publishers", minWidth: 200, isResizable: true, onRender: renderPublishers },
    { key: "version", name: localeStrings["ProductVersion"], fieldName: "version", minWidth: 150, isResizable: true, onRender: renderVersion },
    { key: "scanInitiator", name: localeStrings["ScanInitiator"], fieldName: "scanInitiator", minWidth: 200, isResizable: true, onRender: renderScanInitiator},
    { key: "dateTime", name: localeStrings["ScanInitiationDate"], fieldName: "dateTime", minWidth: 200, isResizable: true, onRender: renderDateTime},
  ];

  const items =
    result !== null &&
    result.map((item: IScanIdColumnView) => ({
      validationId: item.validationId,
      payloadRegistryIds: item.payloadRegistryIds,
      scanIds: item.scanIds,
      publishers: (item.publishers.map((publisher) => {
        return Publishers[publisher];
      })),
      version: item.version,
      createdDateTime: (item.createdDateTime.map((dateTime) => {
        return new Date(dateTime);
      })),
      scanInitiator: item.scanInitiator,
    }));

  if (loading) {
    return <Spinner size={SpinnerSize.medium} />;
  } else if (error) {
    return <GenericErrorScreen error={error} />;
  }

  return (
    <div>
      <div className="title-outer">
        <div>
          <h3>{localeStrings["SearchScanIdsByProductId"]}</h3>
        </div>
        {result && (
          <h3>
            {result && localeStrings["SearchScanIdPageTitle"]}
            {result.length}
          </h3>
        )}
      </div>
      <hr/>
      <div><h4>{localeStrings["FetchedScanDetailsForProductId"]} {productId}</h4></div>
      <div className="table-style-div">
        <DetailsList
          items={items}
          columns={columns}
          layoutMode={DetailsListLayoutMode.fixedColumns}
          selectionPreservedOnEmptyClick={true}
          selectionMode={SelectionMode.none}
        />
      </div>
    </div>
  );
}
