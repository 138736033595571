import * as React from 'react';
import '../../html-constants/styles/html-styles';
import './product-information-result.scss';
import localeStrings from "../../../assets/localeStrings/english.json";
import { ProductType } from '../../lib/enums/common-enums';
import { Link, PrimaryButton, Spinner, SpinnerSize } from '@fluentui/react';
import { useAxiosGet } from '../hooks/useAxiosGet';
import { ExtensionThreatSummaryApis } from '../../configurations/url-configs';
import { URLCreator } from '../../lib/url-creator/url-creator';
import { useParams } from 'react-router';
import { ExtensionThreatAnalysisLogin } from '../../configurations/configuration';
import GenericErrorScreen from '../error-screens/generic-error-screen';

interface IViewPageParams {
    productId: string;
    productVersion: string;
    productType: ProductType
  }

export default function ProductInformationResult(): React.ReactElement {
    const { productId, productVersion, productType } = useParams<IViewPageParams>();
    const { result, loading, error } = useAxiosGet<any>(
        new URLCreator(ExtensionThreatSummaryApis.GetProductInfromation)
            .AddParams("productId", productId)
            .AddParams("productVersion", productVersion)
            .AddParams("productType", productType)
            .GetUrl(),
        ExtensionThreatAnalysisLogin,
    );

    if (loading) {
        return <Spinner size={SpinnerSize.medium} />;
    } else if (error) {
        return <GenericErrorScreen error={error} />;
    }

    return (

        <>
            <h3 className="title">{localeStrings[`FindProductInformation`].replace('Product', ProductType.MicrosoftCopilotPlugin)}</h3>
            <hr />
            <div className='action-pane'>
                <div className="plugin-information-container">
                    <div>
                        <p className="plugin-information-field">{localeStrings[`PluginInformation_Name`]}</p>
                        <p className="plugin-information-value">{result.productName}</p>
                    </div>
                    <div>
                        <p className="plugin-information-field">{localeStrings[`PluginInformation_ProductId`]}</p>
                        <p className="plugin-information-value">{productId}</p>
                    </div>
                    <div>
                        <p className="plugin-information-field">{localeStrings[`PluginInformation_Version`]}</p>
                        <p className="plugin-information-value">{result.version}</p>
                    </div>
                    <div>
                        <p className="plugin-information-field">{localeStrings[`PluginInformation_Type`]}</p>
                        <p className="plugin-information-value">{productType}</p>
                    </div>
                    <div>
                        <p className="plugin-information-field">{localeStrings[`PluginInformation_PublisherId`]}</p>
                        <p className="plugin-information-value">{result.developerId}</p>
                    </div>
                </div>
                <div className='plugin-information-container'>
                    <div className='asset-container'>
                        <p className="plugin-information-field">
                            {localeStrings[`PluginInformation_DeveloperHostedManifest`]}
                        </p>
                        <div className="download-view">
                            <Link onClick={() => window.open(result.devloperHostedManifestUrl, "_blank")}>
                                {result.devloperHostedManifestUrl}
                            </Link>
                        </div>    
                    </div>
                </div>
                <div className='plugin-information-container'>
                    <div className='asset-container'>
                        <p className="plugin-information-field">{localeStrings[`DownloadPluginAsset`]}</p>
                        <div className="download-view">
                            <PrimaryButton className="asset-button" text={localeStrings[`DeveloperSubmittedManifest`]} onClick={() => window.location.href = result.manifestSasUri} allowDisabledFocus iconProps={{ iconName: 'Download' }}/>
                            <PrimaryButton className="asset-button" text={localeStrings[`DownloadApiSpec`]} onClick={() => window.location.href = result.apiSpecSaSUri} allowDisabledFocus iconProps={{ iconName: 'Download' }}/>
                        </div>
                    </div>        
                </div>
            </div>
        </>
    );
}
