import * as React from 'react';
import localeStrings from '../../../assets/localeStrings/english.json';
import Routes from '../../root/routes';
import { IBreadcrumbItem } from '@fluentui/react';
import { useHistory, useLocation } from 'react-router-dom';
import { ProductType } from '../../lib/enums/common-enums';
import { getProduct } from '../../lib/utils/util';

export function getBreadCrumbOptions(): IBreadcrumbItem[] {
  const navigationObjects = {
    Create: Routes.ThreatAnalysisRecordCreate,
    CreateByCrxId: Routes.ThreatAnalysisRecordCreate,
    CreateByBigId: Routes.ThreatAnalysisRecordCreate,
    Edit: Routes.Home,
    View: Routes.ThreatAnalysisRecordSearch,
    Report: Routes.ScanReport,
    Rule: Routes.Rules,
  };
  const [items, setItems] = React.useState<IBreadcrumbItem[]>([]);
  const location = useLocation();
  const [pathName, setPathName] = React.useState<string>(`/${Routes.Home}`);
  const history = useHistory();

  React.useEffect(() => {
    const itemsInternal = [];
    let currPathName = pathName;
    let product = getProduct(location.pathname);
    switch(product) {
      case ProductType.Extension:
        currPathName = `/${ProductType.Extension}/${Routes.Home}`;
        break;
      case ProductType.MicrosoftCopilotPlugin:
        currPathName = `/${ProductType.MicrosoftCopilotPlugin}/${Routes.Home}`;
        break;
      default:
        currPathName = `/${Routes.Home}`;
        break;
    }
    setPathName(currPathName);

    itemsInternal.push({
      key: `key-Home`,
      onClick: () => history.push(currPathName),
      text: localeStrings[`Home_Breadcrumb`],
    });
    location.pathname.split("/").map((item) => {
      if (item?.length > 0 && item !== "Home" && localeStrings[`${item}_Breadcrumb`]) {
        itemsInternal.push({
          key: `key-${item}`,
          onClick: () => history.push(`/${navigationObjects[item]}`),
          text: localeStrings[`${item}_Breadcrumb`],
        });
      }
    });
    setItems(itemsInternal);
  }, [location.pathname]);

  return items;
}
