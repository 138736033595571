import * as React from 'react';
import localeStrings from '../../../../assets/localeStrings/english.json';
import Routes from '../../../root/routes';
import { IInitiateScanResponse } from '../../../lib/interfaces/common-interface';
import { Link, useLocation } from 'react-router-dom';
import './initiate-scan-result.scss';
import { getProduct } from '../../../lib/utils/util';

export default function InitiateScanResponse(props: IInitiateScanResponse): React.ReactElement {
  const productType = getProduct(useLocation().pathname);
  return (
    <div className="outer-div">
      <h3 className="title">{localeStrings[`InitiateScanResponseTitle`]}</h3>
      <p><strong>{localeStrings["ScanId"]}: </strong> {props.scanId}</p>
      <p><strong>{localeStrings["ValidationId"]}: </strong> {props.validationId}</p>
      <p><strong><Link to = {`/${productType}/${Routes.ScanStatus}/${props.productId}/${props.validationId}`}>View Scan Status</Link></strong></p>
    </div>
  );
}