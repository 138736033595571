import {
    DetailsList,
    Dropdown,
    DetailsListLayoutMode,
    IColumn,
    SelectionMode,
    TextField,
    PrimaryButton
} from "@fluentui/react";
import * as React from "react";
import { useEffect, useState } from "react";
import { AttackVectorCategory, Keys } from "../../../lib/enums/common-enums";
import localeStrings from "../../../../assets/localeStrings/english.json";
import { WikiLink } from "../../../configurations/configuration";
import useDropdown from "../../hooks/useDropdown";
import useDropdownMultiSelect from "../../hooks/useDropdownMultiSelect";
import { SearchableMultiselectDropdown } from "../../common-form-componnets/searchable-dropdown/searchable-multselect-dropdown";
import SectionTitleHelperText from "../../view/section-title-helper-text/section-title-helper-text";
import { DropdownOptionsGenerator, IsValidChromeStoreUrl, IsValidUrl } from "../../../lib/utils/util";
import {
    AttackVectorDropdownOptions,
    HashTableIsMaliciousDropdownOptions,
    HashTableIsObfuscatedDropdownOptions,
} from "../dropdown-options";
import "./hash-details-submit.scss";
import { IHashDetailsSubmitProps } from "../../../lib/interfaces/common-interface";

export default function HashDetailsSubmit(props: IHashDetailsSubmitProps): React.ReactElement {

    const maliciousHashes = props.initialData.maliciousHashes;
    const packageHashes = props.initialData.packageHashes;

    if (packageHashes == null)
        return;

    const getFilePath = (packageHashes:Map<string, string>, hashValue: string): string => {
        for (var key of Object.keys(packageHashes)) {
            if (packageHashes[key] == hashValue)
                return key
        }
    };

    const renderMaliciousOptions = (item: any): React.ReactNode => {
        const malicious = useDropdown(
            item.malicious,
            HashTableIsMaliciousDropdownOptions,
            true)

        React.useEffect(() => {
            item.malicious = malicious.selectedItem
        }, [malicious.selectedItem])

        return (<Dropdown
            {...malicious}
        />)
    };

    const renderObfuscatedOptions = (item: any): React.ReactNode => {
        const obfuscated = useDropdown(
            item.obfuscated,
            HashTableIsObfuscatedDropdownOptions,
            true)

        React.useEffect(() => {
            item.obfuscated = obfuscated.selectedItem
        }, [obfuscated.selectedItem])

        return (<Dropdown
            {...obfuscated}
        />)
    };

    const renderDeleteButton = (item: any): React.ReactNode => {
        return item.canDelete && (<PrimaryButton className="asset-button" text={localeStrings["HashDetails_Table_Delete"]} onClick={deleteRow} iconProps={{ iconName: 'Delete' }} />)
    };

    const deleteRow = (hashValue: any) => {
        const updatedTableData = [...tableItems];
        const index = updatedTableData.findIndex(tableItem => tableItem.hashValue == hashValue);
        updatedTableData.splice(index, 1);
        setTableItems(updatedTableData);
        props.setData({
            maliciousHashes: updatedTableData,
        });
    };

    const renderAttackvectorDropdown = (item: any): React.ReactNode => {
        return (<Dropdown
            className="attack-vector-dropdown"
            dropdownWidth="200"
            multiSelect
            {...useDropdownMultiSelect(
                item.attackVectors,
                AttackVectorDropdownOptions,
                { key: Keys.NoneAttackVectorCategory, text: AttackVectorCategory.None }
            )}
        />)
    };

    React.useEffect(() => {
        props.setData({
            maliciousHashes: tableItems,
        });
    }, [tableItems]);

    const columns: IColumn[] = [
        { key: "hashValue", name: localeStrings["HashDetails_Table_HashValue"], fieldName: "hashValue", minWidth: 300, isResizable: true },
        { key: "filePath", name: localeStrings["HashDetails_Table_FilePath"], fieldName: "filePath", minWidth: 300, isResizable: true },
        { key: "attackVectors", name: localeStrings["HashDetails_Table_AttackVectors"], fieldName: "attackVectors", minWidth: 300, isResizable: true, onRender: renderAttackvectorDropdown },
        { key: "malicious", name: localeStrings["HashDetails_Table_Malicious"], fieldName: "malicious", minWidth: 200, isResizable: true, onRender: renderMaliciousOptions },
        { key: "obfuscated", name: localeStrings["HashDetails_Table_Obfuscated"], fieldName: "obfuscated", minWidth: 200, isResizable: true, onRender: renderObfuscatedOptions },
        { key: "delete", name: localeStrings["HashDetails_Table_Delete"], fieldName: "delete", minWidth: 100, isResizable: true, onRender: renderDeleteButton },
    ];

    let items: FileHash[] = [];

    if (maliciousHashes !== null) {
        items = maliciousHashes.map((item) => ({
            hashValue: item.hashValue,
            filePath: getFilePath(packageHashes, item.hashValue),
            canDelete: false,
            malicious: localeStrings['Yes'],
            obfuscated: localeStrings['Unknown'],
            hashAlgo: localeStrings['SHA256'],
        }));
    }

    const [tableItems, setTableItems] = useState(items);

    const addSelectedFiles = () => {
        let updatedTableData = searchableFileDropdown.selectedItems.map(
            (item) => ({
                hashValue: packageHashes[item],
                filePath: item,
                canDelete: true,
                malicious: localeStrings['Yes'],
                obfuscated: localeStrings['Unknown'],
                hashAlgo: localeStrings['SHA256'],
            }));  
        items = [...items, ...updatedTableData];
        setTableItems(items);
        props.setData({
            maliciousHashes: items,
        });
    }

    const searchableFileDropdown = useDropdownMultiSelect([], DropdownOptionsGenerator(Object.keys(packageHashes)), localeStrings["HashDetails_Table_AddFiles"]);

    return (
        <div>
            <SectionTitleHelperText
                title={localeStrings[`HashDetails_SectionTitle`]}
                helperText={localeStrings[`HashDetails_SectionHelpText`]}
                linkText={localeStrings[`LearnMoreText`]}
                link={WikiLink}
            />
            <div className="table-style-div">
                <DetailsList
                    items={tableItems}
                    columns={columns}
                    layoutMode={DetailsListLayoutMode.fixedColumns}
                    selectionPreservedOnEmptyClick={true}
                    selectionMode={SelectionMode.none}
                />
            </div>
            <SearchableMultiselectDropdown
                multiSelect={true}
                {...searchableFileDropdown}
                label={localeStrings["HashDetails_Table_AddFiles"]}
            />
            <PrimaryButton className="asset-button" text="Add" allowDisabledFocus onClick={addSelectedFiles} iconProps={{ iconName: 'Add' }} />
        </div>
    );
}
