import { useMsal } from '@azure/msal-react';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import localeStrings from '../../../assets/localeStrings/english.json';
import Routes from '../../root/routes';
import ActionCards from './action-cards/action-cards';
import './home.scss';
import { ProductType } from '../../lib/enums/common-enums';
import { AccessActions, IsUserAuthorizedForAction } from '../../lib/utils/auth-utils';

export default function PluginHome(): React.ReactElement {
  const history = useHistory();
  const { instance, accounts } = useMsal();

  return (
    <div className="home">
      <h2 className="title">{localeStrings[`PluginTitle`]}</h2>
      <div className="action-cards">
        <ActionCards
          title={localeStrings[`ActionCardViewProductInformation`].replace('Product', ProductType.MicrosoftCopilotPlugin)}
          subTitle={localeStrings[`ActionCardViewProductInformationSubTitle`].replace('Product', ProductType.MicrosoftCopilotPlugin)}
          icon={"info"}
          actionText={localeStrings[`ActionCardSearch`]}
          onClick={() => history.push(`${Routes.Information}`)}
          href={`${window.location.origin}/${Routes.Information}`}
        />
        <ActionCards
          title={localeStrings[`ActionCardViewGetScanDetails`]}
          subTitle={localeStrings[`ActionCardViewGetScanDetailsSubTitle`]}
          icon={"info"}
          actionText={localeStrings[`ActionCardSearch`]}
          onClick={() => history.push(`${Routes.ScanInformation}`)}
          href={`${window.location.origin}/${Routes.ScanInformation}`}
        />
        <ActionCards
          title={localeStrings[`ActionCardViewPluginReport`]}
          subTitle={localeStrings[`ActionCardViewPluginReportSubTitle`]}
          icon={"Search"}
          actionText={localeStrings[`ActionCardViewSubText`]}
          onClick={() => history.push(`${Routes.ScanReport}`)}
          href={`${window.location.origin}/${Routes.ScanReport}`}
        />
        {IsUserAuthorizedForAction(instance.getAccountByHomeId(accounts[0]?.homeAccountId), AccessActions.PluginsInitiateOnDemandScan) 
          ? (
          <>
            <ActionCards
              title={localeStrings[`ActionCardInitiateScan`]}
              subTitle={localeStrings[`ActionCardInitiateScanSubTitle`]}
              icon={"Add"}
              actionText={localeStrings[`ActionCardDoSubText`]}
              onClick={() => history.push(`${Routes.InitiateScan}`)}
              href={`${window.location.origin}/${Routes.InitiateScan}`} />
            <ActionCards
              title={localeStrings[`ActionCardGetScanStatus`]}
              subTitle={localeStrings[`ActionCardGetScanStatusSubTitle`]}
              icon={"info"}
              actionText={localeStrings[`ActionCardViewSubText`]}
              onClick={() => history.push(`${Routes.ScanStatus}`)}
              href={`${window.location.origin}/${Routes.ScanStatus}`} />
            </>
            )
          : ""
        }
      </div>
    </div>
  );
}
