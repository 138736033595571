import { BrowserCacheLocation, Configuration } from "@azure/msal-browser";
import { Environments } from "../lib/enums/common-enums";
import { authConfig, hostURLs } from "../lib/constants/constants";

const getAuthDetails = () => {
  var environment = getEnvironment();
  return authConfig[environment]
}

const getEnvironment = () => {
  var url = window.location.hostname;
  switch(url){
    case hostURLs.development:
      return Environments.Development;
    case hostURLs.test:
    case hostURLs.testTrafficManager:
      return Environments.Test;
    default:
      return Environments.Production;
  }
}

export const WikiLink =
  "https://microsoftapc.sharepoint.com/:w:/r/teams/EdgeExtensionCertifications/_layouts/15/doc2.aspx?sourcedoc=%7BD9CAABCF-654A-4B43-95F3-90118542351E%7D&file=WIKI%20Manual%20code%20review%20process.docx&action=default&mobileredirect=true&DefaultItemOpen=1";
export const MissingMetaDataBugLink = "https://microsoft.visualstudio.com/Edge/_workitems/create/Bug?templateId=4ee6b276-5a84-4d10-956a-926d8a55cedc&ownerId=0f9f3cba-bdff-49e3-ad98-2b55a2bbff86";

export const msalConfig: Configuration = {
  auth: {
    authority: "https://login.microsoftonline.com/72f988bf-86f1-41af-91ab-2d7cd011db47",
    clientId: getAuthDetails().clientId,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    secureCookies: true
  }
};
export const profileLogin = { scopes: ["User.Read"] };

export const ExtensionThreatAnalysisLogin = {
  scopes: getAuthDetails().scope,
};

export const enum NotAuthorizedReason {
  NotSignedIn,
  InCorrectRole,
}
