import { Dropdown, Label, PrimaryButton, TextField } from "@fluentui/react";
import * as React from "react";
import { useHistory, useLocation } from "react-router-dom";
import localeStrings from "../../../../assets/localeStrings/english.json";
import { ScanType, Keys } from "../../../lib/enums/common-enums";
import { SubmissionIdValidation, getProduct } from "../../../lib/utils/util";
import Routes from "../../../root/routes";
import useDropdown from "../../hooks/useDropdown";
import useInput from "../../hooks/useInputValidation";
import { ScanTypeDropdownOptions } from "../dropdown-options";
import "./create-by-submissionid.scss";

export function CreateBySubmissionId(): React.ReactElement {
    const submissionIdInput = useInput(
        "",
        true,
        localeStrings[`SubmissionIdErrorText`],
        SubmissionIdValidation
    );
    const scanTypeDropdown = useDropdown(
        Keys.PrePublishScanType,
        ScanTypeDropdownOptions,
        true,
    );
    const scanIdInput = useInput(
        "",
        scanTypeDropdown.selectedKey === Keys.PostPublishRescanType,
        localeStrings[`RescanIdErrorText`],
        (value) => value.length > 0 || scanTypeDropdown.selectedKey === Keys.PrePublishScanType
    );
    const history = useHistory();

    React.useEffect(() => scanIdInput.setRequired(scanTypeDropdown.selectedKey === Keys.PostPublishRescanType), [scanTypeDropdown.selectedKey]);
    const AreInputsValid = submissionIdInput.isValidInput && scanIdInput.isValidInput;
    const productType = getProduct(useLocation().pathname);
    const fetchData = () => {
        if (!(AreInputsValid)) {
            return
        }
        var submissionId = submissionIdInput.value;
        var scanId = scanTypeDropdown.selectedKey === Keys.PostPublishRescanType ? scanIdInput.value : submissionIdInput.value
        var publisher = scanTypeDropdown.selectedKey === Keys.PostPublishRescanType ? ScanType.PostPublishRescanType : ScanType.PrePublishScanType
        var url = `/${productType}/${Routes.ThreatAnalysisRecordCreate}/${submissionId}/${scanId}/${publisher}`;
        history.push(url);
    };
    return (
        <div className="search-by-submission-id">
            <h1>
                {localeStrings[`CreateReportTitle`]}
            </h1>
            <hr />
            <div className="inputs">
                <Label>Submission Id</Label>
                <TextField
                    {...submissionIdInput}
                    onKeyDown={(e) => e.key === "Enter" && fetchData()}
                    placeholder={localeStrings[`ExtensionMetadata_SubmissionID`]}
                    iconProps={{ iconName: "search" }}
                />
                <Label>{localeStrings[`ScanType`]}</Label>
                <Dropdown
                    className="scan-type-dropdown"
                    aria-label={localeStrings[`ScanTypeDropdown`]}
                    {...scanTypeDropdown}
                />
                {
                    scanTypeDropdown.selectedKey === Keys.PostPublishRescanType &&
                    <>
                        <Label>{localeStrings[`ScanId`]}</Label>
                        <TextField
                            className="scan-id-textbox"
                            {...scanIdInput}
                            onKeyDown={(e) => e.key === "Enter" && fetchData()}
                            placeholder={localeStrings[`RescanId`]}
                            iconProps={{ iconName: "search" }}
                        />
                    </>
                }
                <PrimaryButton onClick={fetchData} disabled={!(AreInputsValid)}>
                    {localeStrings[`PrePopulateData`]}
                </PrimaryButton>
            </div>
        </div>)
}
