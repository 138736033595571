import { useLocation, useParams } from "react-router-dom";
import { ISubmitPageParams } from "../../lib/interfaces/common-interface";
import { ActionType } from "../../lib/enums/common-enums";

export interface IUseSubmitUrlState {
  noMetaDataFound: string;
  isCreate: boolean;
  reportId: string;
  scanId: string;
  publisher: string;
  submissionId: string;
}

export function useSubmitUrlState(): IUseSubmitUrlState {
  const { search } = useLocation();
  const noMetaDataFound = new URLSearchParams(search).get("noMetaData");
  const { reportId, submissionId, scanId, publisher } = useParams<ISubmitPageParams>();

  const isCreate =
    location.pathname.split("/")[3] === ActionType.Create

  return {
    isCreate,
    noMetaDataFound,
    reportId,
    submissionId,
    scanId,
    publisher
  };
}
