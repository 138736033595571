import { AxiosError, AxiosResponse } from "axios";
import { ExtensionThreatAnalysisLogin } from "../../configurations/configuration";
import { ExtensionThreatSummaryApis } from "../../configurations/url-configs";
import { IThreatAnalysisDatabaseRecord } from "../../lib/interfaces/common-interface";
import { URLCreator } from "../../lib/url-creator/url-creator";
import { IAxiosGet, useAxiosGet } from "./useAxiosGet";
import useAxiosPost, { IAxiosPost } from "./useAxiosPost";
import { useSubmitUrlState } from "./useSubmitUrlState";
import { StatusCodes } from 'http-status-codes';

export interface IUseSubmitApi {
  GetSubmitData: () => IAxiosGet<IThreatAnalysisDatabaseRecord>;
  PostData: () => IAxiosPost<IThreatAnalysisDatabaseRecord>;
}
export function useSubmitApi(): IUseSubmitApi {
  const { isCreate, submissionId, reportId, scanId, publisher, noMetaDataFound } = useSubmitUrlState();

  const GetSubmitData = (): IAxiosGet<IThreatAnalysisDatabaseRecord> => {
    if (noMetaDataFound) {
      const result: IAxiosGet<null> = {
        loading: false,
        result: null,
        error: {
          response: { status: StatusCodes.NOT_FOUND } as AxiosResponse<any, any>
        } as AxiosError
      }
      return result;
    }
    if (!isCreate) {
      return useAxiosGet<IThreatAnalysisDatabaseRecord>(
        new URLCreator(ExtensionThreatSummaryApis.GetExtensionReportById).AddRouteParam(reportId).GetUrl(),
        ExtensionThreatAnalysisLogin,
      );
    }
    let url = new URLCreator(ExtensionThreatSummaryApis.GetPrePopulatedDataBySubmissionId)
        .AddParams("submissionId", submissionId)
        .AddParams("publisher", publisher)
        .AddParams("id", scanId)
        .GetUrl();
    return useAxiosGet<IThreatAnalysisDatabaseRecord>(url, ExtensionThreatAnalysisLogin);
  };

  const PostData = (): IAxiosPost<IThreatAnalysisDatabaseRecord> => {
    return useAxiosPost<IThreatAnalysisDatabaseRecord>(
      new URLCreator(
        isCreate ? ExtensionThreatSummaryApis.CreateExtensionReport : ExtensionThreatSummaryApis.UpdateExtensionReport,
      ).GetUrl(),
      ExtensionThreatAnalysisLogin,
    );
  };
  return {
    GetSubmitData,
    PostData,
  };
}
