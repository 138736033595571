import * as React from "react";

export interface ICollectFormData<TUserInput> {
  data: TUserInput;
  setData: (d: TUserInput) => void;
  isCurrentDataValid: boolean;
  initialData: Partial<TUserInput>;
}

export default function useCollectFormData<TUserInput>(initialData: TUserInput): ICollectFormData<TUserInput> {
  const [data, setInternalData] = React.useState<TUserInput>(null);
  const [isCurrentDataValid, setIsCurrentDataValid] = React.useState<boolean>(false);

  const setData = (d: TUserInput) => {
    if (d === null) {
      setIsCurrentDataValid(false);
    } else {
      setIsCurrentDataValid(true);
    }
    setInternalData({ ...initialData, ...d });
  };

  return {
    data,
    initialData,
    isCurrentDataValid,
    setData,
  };
}
