import * as React from "react";

export interface IUseDatePicker {
  onSelectDate: (dateSelected: Date) => void;
  value: Date;
}

export default function useDatePicker(defaultDate: string): IUseDatePicker {
  const [value, setValue] = React.useState<Date>(defaultDate && new Date(defaultDate));
  const onSelectDate = (dateSelected: Date) => {
    setValue(dateSelected);
  };

  React.useEffect(() => {}, []);

  return {
    onSelectDate,
    value,
  };
}
