import { CommandButton, Spinner, SpinnerSize } from "@fluentui/react";
import * as React from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import localeStrings from "../../../assets/localeStrings/english.json";
import { ExtensionThreatAnalysisLogin } from "../../configurations/configuration";
import { ExtensionThreatSummaryApis } from "../../configurations/url-configs";
import { IThreatAnalysisDatabaseRecord } from "../../lib/interfaces/common-interface";
import { URLCreator } from "../../lib/url-creator/url-creator";
import {
  ExtractAuditDetailsFromRecord,
  ExtractDeveloperDetailsDataFromRecord,
  ExtractExtensionAnalysisDataFromRecord,
  ExtractExtensionMetaDataFromRecord,
  ExtractThreatDetailsDataFromRecord,
  getProduct,
} from "../../lib/utils/util";
import Routes from "../../root/routes";
import GenericErrorScreen from "../error-screens/generic-error-screen";
import { useAxiosGet } from "../hooks/useAxiosGet";
import AuditDetails from "./audit-details/audit-details";
import DeveloperDetailsView from "./developer-details/developer-details-view";
import ExtensionAnalysisView from "./extension-analysis/extension-analysis-view";
import ExtensionMetadataView from "./extension-metadata/extension-metadata-view";
import ThreatDetailsView from "./threat-details/threat-details-view";
import "./view.scss";

export interface IViewPageParams {
  reportId: string;
}

export default function View(): React.ReactElement {
  const product = getProduct(useLocation().pathname);
  const { reportId } = useParams<IViewPageParams>();
  const history = useHistory();
  const { result, loading, error } = useAxiosGet<IThreatAnalysisDatabaseRecord>(
    new URLCreator(ExtensionThreatSummaryApis.GetExtensionReportById).AddRouteParam(reportId).GetUrl(),
    ExtensionThreatAnalysisLogin,
  );

  if (loading) {
    return <Spinner size={SpinnerSize.medium} />;
  } else if (error) {
    return <GenericErrorScreen error={error} />;
  }

  return (
    <div>
      <h2 className="title">{`${localeStrings[`View_Title`]}${result.reviewName}`}</h2>
      <hr />
      <div className="action-pane">
        <CommandButton
          iconProps={{ iconName: "ContextMenu" }}
          text={localeStrings[`GoToDashboard`]}
          onClick={() => history.push(`/${product}/${Routes.Home}`)}
        />
        <CommandButton
          iconProps={{ iconName: "Sync" }}
          text={localeStrings[`UpdateEdit`]}
          onClick={() => history.push(`/${product}/${Routes.ThreatAnalysisRecordEdit}/${reportId}`)}
        />
        <hr />
        <div className="view-layout">
          <div>
            <ExtensionMetadataView {...ExtractExtensionMetaDataFromRecord(result)} />
            <DeveloperDetailsView {...ExtractDeveloperDetailsDataFromRecord(result)} />
            <ThreatDetailsView {...ExtractThreatDetailsDataFromRecord(result)} />
            <ExtensionAnalysisView {...ExtractExtensionAnalysisDataFromRecord(result)} />
          </div>
          <AuditDetails {...ExtractAuditDetailsFromRecord(result)} />
        </div>
      </div>
    </div>
  );
}
