import * as React from "react";
import { useHistory } from "react-router-dom";
import localeStrings from "../../../assets/localeStrings/english.json";
import Routes from "../../root/routes";
import ActionCards from "./action-cards/action-cards";
import "./home.scss";
import { ProductType } from "../../lib/enums/common-enums";

export default function Home(): React.ReactElement {
  const history = useHistory();

  return (
    <div className="home">
      <h2 className="title">{localeStrings[`Home_Title`]}</h2>
      <div className="action-cards">
      <ActionCards
            title={localeStrings[`EdgeBrowserExtension`]}
            subTitle=""
            icon={"View"}
            actionText={localeStrings[`ActionCardViewSubText`]}
            onClick={() => history.push(`${ProductType.Extension}/${Routes.Home}`)}
            href={`${window.location.origin}/${ProductType.Extension}/${Routes.Home}`}
          />
          <ActionCards
            title={localeStrings[`MicrosoftCopilotPlugin`]}
            subTitle=""
            icon={"View"}
            actionText={localeStrings[`ActionCardViewSubText`]}
            onClick={() => history.push(`${ProductType.MicrosoftCopilotPlugin}/${Routes.Home}`)}
            href={`${window.location.origin}/${ProductType.MicrosoftCopilotPlugin}/${Routes.Home}`}
          />
      </div>
    </div>
  );
}