import { DropdownMenuItemType, SearchBox, Dropdown, IDropdownOption, IDropdownProps } from "@fluentui/react";
import * as React from "react";
import "./searchable-multiselect-dropdown.scss"
export const SearchableMultiselectDropdown: React.FunctionComponent<IDropdownProps> = (props) => {
    const [searchText, setSearchText] = React.useState<string>('');

    function renderOption(option: IDropdownOption): JSX.Element {
        return (option.itemType === DropdownMenuItemType.Header && option.key === "FilterHeader") ?
            <SearchBox onChange={(ev, newValue) => setSearchText(newValue)} underlined={true} placeholder="Search options" /> : <>{option.text}</>;
    }

    return (
        <Dropdown
            className="searchable-multiselect-dropdown"
            multiSelect
            {...props}
            options={[
                { key: 'FilterHeader', text: '-', itemType: DropdownMenuItemType.Header },
                { key: 'divider_filterHeader', text: '-', itemType: DropdownMenuItemType.Divider },
                ...props.options.map(option => !option.disabled && option.text.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ?
                    option : { ...option, hidden: true }
                ),
            ]}
            onRenderOption={renderOption}
            onDismiss={() => setSearchText('')}
        />
    );

};
