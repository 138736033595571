import * as React from 'react';
import localeStrings from '../../../../assets/localeStrings/english.json';
import Routes from '../../../root/routes';
import useInput from '../../hooks/useInputValidation';
import { getProduct, isValidGuid } from '../../../lib/utils/util';
import {
  PrimaryButton,
  TextField
  } from '@fluentui/react';
import { useHistory, useLocation } from 'react-router';
import './scan-status.scss';
import '../../../html-constants/styles/html-styles';

export default function ScanStatus(): React.ReactElement {
  const history = useHistory();
  const productType = getProduct(useLocation().pathname);
  const submit = () => {
    const productId = productIdInputBox.value;
    const validationId = validationIdInputBox.value;
    const route = `/${productType}/${Routes.ScanStatus}/${productId}/${validationId}`
    history.push(route);
  };
  
  const productIdInputBox = useInput("", true, localeStrings["ProductIdErrorText"], (value: string): boolean => isValidGuid(value));
  const validationIdInputBox = useInput("", true, localeStrings["ValidationIdErrorText"], (value: string): boolean => isValidGuid(value));

  return (
    <div className="outer-div">
      <h2 className="title">{localeStrings[`ScanStatusTitle`]}</h2>
      <hr />
        <div className="input-div">
          <h4>{localeStrings[`EnterProductId`]}</h4>
          <TextField
            placeholder={localeStrings[`ProductId`]}
            {...productIdInputBox}
            iconProps={{ iconName: "search" }}
          />
          <h4>{localeStrings[`EnterValidationId`]}</h4>
          <TextField
            placeholder={localeStrings[`ValidationId`]}
            {...validationIdInputBox}
            iconProps={{ iconName: "search" }}
          />
          <hr/>
          <div>
            <PrimaryButton onClick={submit} disabled={!productIdInputBox.isValidInput || !validationIdInputBox.isValidInput}>
              {localeStrings[`Submit`]}
            </PrimaryButton>
          </div>
        </div>
    </div>
  );
}
