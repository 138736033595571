import { IDropdownOption } from "@fluentui/react";
import * as React from "react";
import localeStrings from "../../../assets/localeStrings/english.json";

export interface IUseDropdownInput {
  isValidInput: boolean;
  onChange: (e: React.FormEvent<HTMLDivElement>, newValue: IDropdownOption<string>) => void;
  selectedKey?: string | number;
  selectedItem?: string;
  errorMessage: string;
  required: boolean;
  options: IDropdownOption[];
  updateOptions: (v: IDropdownOption[]) => void;
}

export default function useDropdown(
  defaultSelectedItem: string,
  options: IDropdownOption[],
  required: boolean = false,
  errorHelpText = localeStrings[`GenericDropDownErrorText`],
): IUseDropdownInput {
  const [possibleOptions, setPossibleOptions] = React.useState<IDropdownOption[]>(options);
  const [selectedKey, setSelectedKey] = React.useState<string | number>(
    possibleOptions.filter((e) => e.text === defaultSelectedItem)[0]?.key,
  );
  const [selectedItem, setSelectedItem] = React.useState<string>(selectedKey ? defaultSelectedItem : null);
  const [isValidInput, setIsValidInput] = React.useState<boolean>(selectedKey ? true : false);
  const [errorMessage, setErrorMessage] = React.useState<string>(errorHelpText);

  React.useEffect(() => {
    const items = possibleOptions.filter((e) => e.text === defaultSelectedItem)[0];
    setSelectedItem(items?.text);
    setSelectedKey(items?.key as string);
    setIsValidInput(items?.text ? true : false);
    setErrorMessage(!items?.text && required ? errorHelpText : "");
  }, []);

  const onChange = (e: React.FormEvent<HTMLDivElement>, newValue: IDropdownOption) => {
    setSelectedKey(possibleOptions.filter((item) => item.text === newValue.text)[0].key);
    setSelectedItem(newValue.text);
    setIsValidInput(newValue ? true : false);
    setErrorMessage(!newValue && required ? errorHelpText : "");
  };
  return {
    errorMessage,
    isValidInput,
    onChange,
    options: possibleOptions,
    required,
    selectedItem,
    selectedKey,
    updateOptions: (v) => setPossibleOptions(v),
  };
}
