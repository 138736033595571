import { Spinner, SpinnerSize } from "@fluentui/react";
import * as React from "react";
import localeStrings from "../../../assets/localeStrings/english.json";
import GenericErrorScreen from "../error-screens/generic-error-screen";
import { useAxiosGet } from "../hooks/useAxiosGet";
import { ExtensionThreatAnalysisLogin } from "../../configurations/configuration";
import { ExtensionThreatSummaryApis } from "../../configurations/url-configs";
import { URLCreator } from "../../lib/url-creator/url-creator";
import { Keys } from "../../lib/enums/common-enums";
import useDropdown from "../hooks/useDropdown";
import { SearchableMultiselectDropdown } from "../common-form-componnets/searchable-dropdown/searchable-multselect-dropdown";
import { DropdownOptionsGenerator } from "../../lib/utils/util";
import RuleSummary from "./rule-summary";
import "./rule.scss";

export default function Rule(): React.ReactElement {
    const { result: allRules, loading: allRulesLoading, error: error } = useAxiosGet<string[]>(
        new URLCreator(ExtensionThreatSummaryApis.GetAllRuleIds).GetUrl(),
        ExtensionThreatAnalysisLogin,
    );
    const ruleIdsDropdown = useDropdown(Keys.DefaultRuleId, [], true);

    React.useEffect(() => {
        if (allRules != null) {
            ruleIdsDropdown.updateOptions(DropdownOptionsGenerator(allRules));
        }
    }, [allRules])

    if (allRulesLoading) {
        return <Spinner size={SpinnerSize.medium} />;
    } else if (error) {
        return <GenericErrorScreen error={error} />;
    }

    return (
        <div>
            <h2 className="title">
                {localeStrings[`SearchRuleId`]}
            </h2>
            <hr />
            <div>
                <div className="search-rule-outer-div">
                    <h4 className="ruleid-ddl-title">{localeStrings[`SelectRuleId`]}</h4>
                    <SearchableMultiselectDropdown
                        multiSelect={false}
                        {...ruleIdsDropdown}
                        ariaLabel={localeStrings[`RuleIdsDropdown`]}
                    />
                </div>
                <div>
                    {ruleIdsDropdown.selectedItem && <RuleSummary title={localeStrings[`RuleIdDetails`]} ruleId={ruleIdsDropdown.selectedItem} />}
                </div>
            </div>
            <div>
            </div>
        </div>
    );
}
