export const MsGraphGetProfile = "https://graph.microsoft.com/v1.0/me";

export enum ExtensionThreatSummaryApis {
    GetExtensionReportById = "api/researcher/getRecord",
    GetPrePopulatedDataBySubmissionId = "api/prepopulate/GetPrepopulatedDataByPubsubMessage",
    CreateExtensionReport = "api/researcher/createRecord",
    UpdateExtensionReport = "api/researcher/updateRecord",
    GetFilteredSearchList = "api/analyser/getFilteredSearch",
    GetScanReportByScanId = "api/Report/scanReport",
    GetPluginScanReportByScanId = "api/Report/scanReportV2",
    GetURLReputations = "api/Report/URLReputations",
    GetLatestSubmissionIdByCrxId = "api/ExtensionInformation/GetLatestSubmissionIdByCrxId",
    GetExtensionInformationBySubmissionId = "api/ExtensionInformation/GetExtensionInformationBySubmissionId",
    GetSasUrlBySubmissionId = "api/ExtensionInformation/GetSasUrlBySubmissionId",
    GetAllRuleIds = "api/Rules/GetAllIds",
    GetRuleDetailsByRuleId = "api/Rules/GetRuleDetails",
    GetScanIdByValidationIdAndProductId = "api/ProductScanInformation/GetScanDetails",
    GetScanStatusByProductIdAndValidationId = "api/v1/onDemandScan/GetStatus",
    InitiateScanByProductIdAndVersion = "api/v1/onDemandScan/Initiate",
    GetBlobReportByScanId = "api/Report/blobUrls",
    GetAllVersionsByProductId = "api/ProductInformation/versions",
    GetProductInfromation = "api/ProductInformation",
}
