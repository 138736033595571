import { IChoiceGroupOption } from "@fluentui/react";
import * as React from "react";

export interface IUseChoiceGroup {
  onChange: (ev: React.SyntheticEvent<HTMLElement>, option: IChoiceGroupOption) => void;
  options: IChoiceGroupOption[];
  selectedKey: string | undefined;
  changeSelectedKey: (option: IChoiceGroupOption) => void;
}

export default function useChoiceGroup(
  defaultValue: IChoiceGroupOption,
  options: IChoiceGroupOption[],
): IUseChoiceGroup {
  const [selectedKey, setSelectedKey] = React.useState<string | undefined>(defaultValue.key);

  const onChange = React.useCallback((ev: React.SyntheticEvent<HTMLElement>, option: IChoiceGroupOption): void => {
    setSelectedKey(option.key);
  }, []);

  React.useEffect(() => { }, []);

  const changeSelectedKey = (newValue: IChoiceGroupOption) => setSelectedKey(newValue.key);

  return {
    onChange,
    selectedKey,
    options,
    changeSelectedKey
  };
}
