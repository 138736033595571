import * as React from "react";
import localeStrings from "../../../../../assets/localeStrings/english.json";
import { SearchNoResultsIcon } from "../../../../icons/search-no-results";
import "./search-no-results.scss";

export default function SearchNoResults(): React.ReactElement {
  return (
    <div className="search-no-results">
      <SearchNoResultsIcon />
      {localeStrings[`SearchNoResultsText`]}
    </div>
  );
}
