import { TooltipHost } from "@fluentui/react";
import * as React from "react";
import localeStrings from "../../../../assets/localeStrings/english.json";
import { WikiLink } from "../../../configurations/configuration";
import { IDeveloperDetails } from "../../../lib/interfaces/common-interface";
import SectionTitleHelperText from "../section-title-helper-text/section-title-helper-text";
import "./developer-details-view.scss";

export default function DeveloperDetailsView(props: IDeveloperDetails): React.ReactElement {
  return (
    <div className={"developer-details"}>
      <SectionTitleHelperText
        title={localeStrings[`DeveloperDetails_SectionTitle`]}
        helperText={localeStrings[`DeveloperDetails_SectionHelpText`]}
        linkText={localeStrings[`LearnMoreText`]}
        link={WikiLink}
      />
      <div className="content">
        <div>
          <p className="field-title">{localeStrings[`DeveloperDetails_DeveloperName`]}</p>
          <p className="field-value">{props.developerName}</p>
        </div>
        <div>
          <p className="field-title">{localeStrings[`DeveloperDetails_DeveloperAccountType`]}</p>
          <p className="field-value">{props.developerAccountType}</p>
        </div>
        <div>
          <p className="field-title ">{localeStrings[`DeveloperDetails_DeveloperWebsite`]}</p>
          <TooltipHost content={props.developerWebsite} closeDelay={5000}>
            <p className="field-value">{props.developerWebsite}</p>
          </TooltipHost>
        </div>
        <div>
          <p className="field-title">{localeStrings[`DeveloperDetails_DeveloperPrivacyPolicyUrl`]}</p>
          <TooltipHost content={props.privacyPolicyURL} closeDelay={5000}>
            <p className="field-value privacy">{props.privacyPolicyURL}</p>
          </TooltipHost>
        </div>
        <div>
          <p className="field-title">{localeStrings[`PublisherId`]}</p>
          <p className="field-value">{props.publisherId}</p>
        </div>        
      </div>
    </div>
  );
}
