import * as React from "react";
import localeStrings from "../../../../assets/localeStrings/english.json";
import { WikiLink } from "../../../configurations/configuration";
import { IThreatDetails } from "../../../lib/interfaces/common-interface";
import TextWithReadMore from "../../common-form-componnets/text-with-read-more/text-with-read-more";
import SectionTitleHelperText from "../section-title-helper-text/section-title-helper-text";
import "./threat-details-view.scss";

export default function ThreatDetailsView(props: IThreatDetails): React.ReactElement {
  return (
    <div className={"threat-details"}>
      <SectionTitleHelperText
        title={localeStrings[`ThreatDetails_SectionTitle`]}
        helperText={localeStrings[`ThreatDetails_SectionHelpText`]}
        linkText={localeStrings[`LearnMoreText`]}
        link={WikiLink}
      />
      <div className="content">
        <div>
          <p className="field-title">{localeStrings[`ThreatDetails_SourceOfReport`]}</p>
          <p className="field-value">{props.sourceOfReport}</p>
        </div>
        <div>
          <p className="field-title">{localeStrings[`ThreatDetails_PublishState`]}</p>
          <p className="field-value">{props.publishType}</p>
        </div>
        <div className="report-symptoms">
          <p className="field-title description">{localeStrings[`ThreatDetails_SymptomsOrBehavior`]}</p>
          <TextWithReadMore text={props.symptomsOrBehavior} />
        </div>
      </div>
    </div>
  );
}
