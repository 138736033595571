import { DefaultButton, PrimaryButton } from "@fluentui/react";
import * as React from "react";
import { useHistory, useLocation } from "react-router-dom";
import localeStrings from "../../../../assets/localeStrings/english.json";
import { MissingMetaDataBugLink } from "../../../configurations/configuration";
import Routes from "../../../root/routes";
import "./no-metadata-found.scss";
import { getProduct } from "../../../lib/utils/util";

export interface INoMetadataFound {
  submissionId: string;
}

export default function NoMetadataFound(props: INoMetadataFound) {
  const history = useHistory();
  const [urlToPush, setUrlToPush] = React.useState<string>("");
  const productType = getProduct(useLocation().pathname);
  React.useEffect(() => {
    if (props.submissionId) {
      var url = `/${productType}/${Routes.ThreatAnalysisRecordCreate}/${props.submissionId}`
      setUrlToPush(url);
    }
  }, [props.submissionId])
  return (
    <div className="no-crxid-found">
      {localeStrings[`MissingMetadataText`]}
      <br /> {localeStrings[`MissingMetadataNote`]}
      <div className="actions">
        <PrimaryButton href={MissingMetaDataBugLink} target="_blank">
          {localeStrings[`RaiseABug`]}
        </PrimaryButton>
        <DefaultButton
          onClick={() => history.push(`${urlToPush}?noMetaData=true`)}
        >
          {localeStrings[`ContinueAhead`]}
        </DefaultButton>
      </div>
    </div>
  );
}
