import { IColumn } from "@fluentui/react";

export const SearchColumns: IColumn[] = [
    {
        key: "column1",
        name: "Name",
        fieldName: "reviewName",
        minWidth: 100,
        maxWidth: 400,
    },
    { key: "column3", name: "Big ID", fieldName: "bigId", minWidth: 50, maxWidth: 120 },
    { key: "column4", name: "CRX ID", fieldName: "crxId", minWidth: 100, maxWidth: 240 },
    { key: "column5", name: "Report state", fieldName: "reportState", minWidth: 60, maxWidth: 100 },
    { key: "column9", name: "Is Malicious", fieldName: "isMalicious", minWidth: 60, maxWidth: 100 },
    { key: "column7", name: "Report source", fieldName: "sourceOfReport", minWidth: 100, maxWidth: 300 },
    { key: "column8", name: "PublishState", fieldName: "publishType", minWidth: 60, maxWidth: 100 },
];
