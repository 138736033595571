import { Breadcrumb } from "@fluentui/react";
import * as React from "react";
import { getBreadCrumbOptions } from "../../hooks/getBreadCrumbOptions";

const breadcrumbStyle = {
  itemLink: {
    color: "#605E5C",
    fontSize: "14px",
  },
  root: {
    margin: "2px 0 4px 0",
  },
};

export default function Navigation(): React.ReactElement {
  const items = getBreadCrumbOptions();
  return (
    <>
      <Breadcrumb styles={breadcrumbStyle} className="breadcrumb" items={items} />
      <hr />
    </>
  );
}
