import { useMsal } from "@azure/msal-react";
import { DefaultButton, PrimaryButton } from "@fluentui/react";
import * as React from "react";
import { useHistory } from "react-router-dom";
import localeStrings from "../../../../assets/localeStrings/english.json";
import { NotAuthorizedReason, profileLogin } from "../../../configurations/configuration";
import { errorIcon } from "../../../icons/error";
import Routes from "../../../root/routes";

export const unAuthLayout = (reason: NotAuthorizedReason): React.ReactElement => {
  const { instance } = useMsal();
  const history = useHistory();

  return (
    <div className="unauthorized">
      <h1>{localeStrings[`Unauthorized`]}</h1>
      {errorIcon()}
      {reason === NotAuthorizedReason.NotSignedIn ? (
        <>
          <h3>{localeStrings[`Unauthorized_Reason_NotSignedIn`]}</h3>
          <PrimaryButton
            text={localeStrings[`SignIn`]}
            onClick={() => {
              const currentUrl = window.location.href;
              history.push(`/${Routes.Home}`);
              instance.loginPopup(profileLogin).then(() => window.location.assign(currentUrl));
            }}
          />
        </>
      ) : (
        <>
          <h3>{localeStrings[`Unauthorized_Reason_IncorrectRole`]}</h3>
          <DefaultButton
            text={localeStrings[`SignOut`]}
            onClick={() => {
              instance.logout();
            }}
          />
        </>
      )}
    </div>
  );
};
