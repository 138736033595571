import { Spinner, SpinnerSize } from "@fluentui/react";
import * as React from "react";
import ReactJson from "react-json-view";
import { useParams } from "react-router-dom";
import { ExtensionThreatAnalysisLogin } from "../../configurations/configuration";
import { ExtensionThreatSummaryApis } from "../../configurations/url-configs";
import { URLCreator } from "../../lib/url-creator/url-creator";
import GenericErrorScreen from "../error-screens/generic-error-screen";
import { useAxiosGet } from "../hooks/useAxiosGet";
import ViewMaliciousUrls from "./view-malicious-url";
import { Keys } from "../../lib/enums/common-enums";
import { IViewPluginSummaryRouteParams } from "../../lib/interfaces/common-interface";

export default function ViewPluginScanReport(): React.ReactElement {
    const { scanId, scanner, scanType } = useParams<IViewPluginSummaryRouteParams>();
    const { result, loading, error } = useAxiosGet<any>(
        new URLCreator(ExtensionThreatSummaryApis.GetPluginScanReportByScanId)
            .AddParams("scanId", scanId)
            .AddParams("listener", scanner)
            .AddParams("publisher", scanType)
            .GetUrl(),
        ExtensionThreatAnalysisLogin,
    );
    const evalReportCollapseDepth = 4;
    const collapseReportScanners: string[] = [Keys.EvalScan, Keys.EvalRAIScan];
    const collapsed = (collapseReportScanners.indexOf(scanner) !== -1) ? evalReportCollapseDepth : false;

    if (loading) {
        return <Spinner size={SpinnerSize.medium} />;
    }

    if (error) {
        return <GenericErrorScreen error={error} />;
    }
    return (
        <div>
            <ReactJson enableClipboard={false} displayDataTypes={false} quotesOnKeys={false} src={result} collapsed={collapsed} />
            <ViewMaliciousUrls scanType={scanType} scanId={scanId} data={result}></ViewMaliciousUrls>
        </div>
    );
}