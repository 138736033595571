import { Link } from "@fluentui/react";
import * as React from "react";
import localeStrings from "../../../../assets/localeStrings/english.json";
import "./text-with-read-more.scss";

export interface ITextWithReadMoreProps {
  text: string;
}
export default function TextWithReadMore(props: ITextWithReadMoreProps) {
  const [isReadMore, setIsReadMore] = React.useState(true);
  const [shouldShowReadMore, setShouldShowReadMore] = React.useState(false);
  const textRef = React.useRef<HTMLParagraphElement>();

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
    textRef.current.style.display = isReadMore ? "block" : "-webkit-box";
  };
  React.useEffect(() => {
    setShouldShowReadMore(textRef.current.scrollHeight !== textRef.current.offsetHeight);
  }, []);
  return (
    <>
      <p ref={textRef} className="text">
        {props.text}
      </p>
      <span onClick={toggleReadMore}>
        {shouldShowReadMore ? <Link>{isReadMore ? localeStrings[`ReadMore`] : localeStrings[`ReadLess`]}</Link> : null}
      </span>
    </>
  );
}
