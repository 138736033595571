import { Spinner, SpinnerSize } from "@fluentui/react";
import * as React from "react";
import ReactJson from "react-json-view";
import { ExtensionThreatAnalysisLogin } from "../../configurations/configuration";
import { ExtensionThreatSummaryApis } from "../../configurations/url-configs";
import { URLCreator } from "../../lib/url-creator/url-creator";
import GenericErrorScreen from "../error-screens/generic-error-screen";
import { IAxiosGet, useAxiosGet } from "../hooks/useAxiosGet";
import { IMaliciousReportResponse, IMaliciousResponse, IReport } from "../../lib/interfaces/common-interface";
import { RuleTypes } from "../../lib/enums/common-enums";

interface IViewMaliciousUrlsProps {
    scanId: string;
    scanType: string;
    data: IReport;
}

export default function ViewMaliciousUrls(props: IViewMaliciousUrlsProps): React.ReactElement {
    const { scanId, scanType, data } = props;
    let requiredMaliciousReport = null;
    if (data) {
        requiredMaliciousReport =
            data.ScanReport &&
            data.ScanReport.detectedRules &&
            data.ScanReport.detectedRules.filter((data) => {
                return Object.values(RuleTypes).includes(data as RuleTypes);
            });
    }
    const requiredReport: string =
        requiredMaliciousReport && requiredMaliciousReport.length > 0 ? requiredMaliciousReport.join(",") : null;
    if (requiredReport == null) {
        return <></>;
    }
    const GetMaliciousReport = (RuleIdConstant: RuleTypes): IAxiosGet<IMaliciousReportResponse> => {
        return useAxiosGet<IMaliciousReportResponse>(
            new URLCreator(ExtensionThreatSummaryApis.GetURLReputations)
                .AddParams("scanId", scanId)
                .AddParams("publisher", scanType)
                .AddParams("ruleIdConstant", RuleIdConstant)
                .GetUrl(),
            ExtensionThreatAnalysisLogin,
        );
    };
    const mReport: IMaliciousResponse[] = [];
    requiredReport.split(",").forEach((ruleIdConstant: RuleTypes) => {
        mReport.push({
            reportName: ruleIdConstant,
            reportResponse: GetMaliciousReport(ruleIdConstant),
        });
    });
    const isLoadingInProgress = mReport.some((x) => x.reportResponse["loading"] == true);
    const anyError = mReport.some((x: IMaliciousResponse) => x.reportResponse["error"]);
    if (isLoadingInProgress) {
        return <Spinner size={SpinnerSize.medium} />;
    } else if (anyError) {
        const error = mReport.filter((x: IMaliciousResponse) => x.reportResponse["error"]);
        return <GenericErrorScreen error={error && error[0].reportResponse.error} />;
    }
    const reportJSON = mReport.map((data) => {
        return {
            name: data.reportName,
            report: data.reportResponse.result,
        };
    });

    return <ReactJson enableClipboard={false} displayDataTypes={false} quotesOnKeys={false} src={reportJSON} />;
}
