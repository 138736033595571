import * as React from "react";
import localeStrings from "../../../../assets/localeStrings/english.json";
import { IAuditDetails } from "../../../lib/interfaces/common-interface";
import "./audit-details.scss";

export default function AuditDetails(props: IAuditDetails): React.ReactElement {
  return (
    <div className={"audit-details"}>
      <div>
        <p className="section-title">{localeStrings[`AuditDetails`]}</p>
      </div>
      <div>
        <p className="field-title">{localeStrings[`CreatedBy`]}</p>
        {props.createdBy? ( 
          <p className="field-value">{props.createdBy}</p> 
          ): <p className="field-value">{localeStrings[`NotAvailable`]}</p>}
      </div>
      <div>
        <p className="field-title">{localeStrings[`CreatedTime`]}</p>
        {props.createdTime? ( 
          <p className="field-value">{props.createdTime}</p> 
          ): <p className="field-value">{localeStrings[`NotAvailable`]}</p>}
      </div>
      <div>
        <p className="field-title">{localeStrings[`LastUpdateBy`]}</p>
        {props.lastUpdatedBy? ( 
          <p className="field-value">{props.lastUpdatedBy}</p> 
          ): <p className="field-value">{localeStrings[`NotAvailable`]}</p>}
      </div>
      <div>
          <p className="field-title">{localeStrings[`UpdateTime`]}</p>
        {props.lastUpdatedTime? ( 
          <p className="field-value">{props.lastUpdatedTime}</p> 
          ): <p className="field-value">{localeStrings[`NotAvailable`]}</p>}
      </div>
    </div>
  );
}
