import { ChoiceGroup, PrimaryButton, TextField } from "@fluentui/react";
import * as React from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Routes from "../../root/routes";
import useChoiceGroup from "../hooks/useChoiceGroup";
import useInput from "../hooks/useInputValidation";
import { SearchByOptionsForExtensionInformation } from "../submit/dropdown-options";
import localeStrings from "../../../assets/localeStrings/english.json";
import "./extension-information.scss";
import { Keys, ProductType } from "../../lib/enums/common-enums";
import { ExtensionInformationBySubmissionId } from "./extension-info-by-submission-id/extension-info-by-submission-id";
import { ExtensionInformationByCrxId } from "./extension-info-by-crxid/extension-info-by-crxid";
import { CRXIdValidation, SubmissionIdValidation, getProduct } from "../../lib/utils/util";

enum PageType {
    Default,
    WithCrxId,
    WithSubmissionId
}

export interface IViewPageParams {
    submissionId: string;
    crxId: string;
}

export function ExtensionInformation(): React.ReactElement {
    const productType = getProduct(useLocation().pathname);
    const history = useHistory();
    const [pageType, setPageType] = React.useState<PageType>(PageType.Default);
    const { submissionId, crxId } = useParams<IViewPageParams>();
    const querySelector = useChoiceGroup(SearchByOptionsForExtensionInformation[0], SearchByOptionsForExtensionInformation);
    React.useEffect(() => {
        searchBox.updateValidator(querySelector.selectedKey === Keys.SearchByCrxId? CRXIdValidation: SubmissionIdValidation);
    }, [querySelector]);
    React.useEffect(() => {
        if (location.pathname.split("/")[1] == null) {
            setPageType(PageType.Default)
            querySelector.changeSelectedKey(SearchByOptionsForExtensionInformation[0])
        }
        else if (location.pathname.includes(Routes.InformationByCrxId)) {
            setPageType(PageType.WithCrxId)
            querySelector.changeSelectedKey(SearchByOptionsForExtensionInformation[1])
        }
        else if (location.pathname.includes(Routes.InformationBySubmissionId)) {
            setPageType(PageType.WithSubmissionId)
            querySelector.changeSelectedKey(SearchByOptionsForExtensionInformation[0])
        }
    }, []);
    const searchBox = useInput(
        crxId || submissionId || "",
        true,
        querySelector.selectedKey === Keys.SearchByCrxId? localeStrings["ProvideCrxId"]: localeStrings["SubmissionIdErrorText"],
        SubmissionIdValidation
    );
    const search = () => {
        if(searchBox.isValidInput) {
            history.replace(
                `/${querySelector.selectedKey === Keys.SearchByCrxId
                    ? `${productType}/${Routes.InformationByCrxId}`
                    : `${productType}/${Routes.InformationBySubmissionId}`
                }/${searchBox.value}`,
            );
        }
    }

    return (
        <div>
            <h2 className="title">
                {localeStrings[`FindProductInformation`].replace('Product', ProductType.Extension)}
            </h2>
            <hr />
            <div className="extension-information">
                <ChoiceGroup className="extension-information-radio-group"  {...querySelector} />
                <TextField
                    placeholder={localeStrings[`EnterToSearch`]}
                    className="extension-search-box"
                    {...searchBox}
                    onKeyDown={(event) => event.key === "Enter" && search()}
                    iconProps={{ iconName: "search" }}
                />
                <PrimaryButton onClick={search} disabled={!searchBox.isValidInput} className="search-button">
                    {localeStrings[`Search`]}
                </PrimaryButton>
            </div>
            {pageType === PageType.WithCrxId && <ExtensionInformationByCrxId crxId={crxId} />}
            {pageType === PageType.WithSubmissionId &&
                <ExtensionInformationBySubmissionId submissionId={submissionId} title={localeStrings[`SubmissionDetails`]} />}
        </div>
    );
}
