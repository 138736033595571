import * as React from "react";
import { useParams } from "react-router-dom";
import { Keys } from "../../lib/enums/common-enums";
import DownloadPluginReports from "./download-plugin-reports";
import localeStrings from "../../../assets/localeStrings/english.json"
import ViewPluginScanReport from "./plugin-scan-report";
import { IViewPluginSummaryRouteParams } from "../../lib/interfaces/common-interface";

export default function ViewPluginSummary(): React.ReactElement {
    const { scanId, scanner, scanType } = useParams<IViewPluginSummaryRouteParams>();
    const downloadReportScanners: string[] = [Keys.EvalScan, Keys.EvalRAIScan];
    const showDownloadReportsButton = (downloadReportScanners.indexOf(scanner) !== -1);

    return (
        <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                    <p><b>{localeStrings['Scanner']}:</b> {scanner}</p>
                    <p><b>{localeStrings['ScanId']}:</b> {scanId}</p>
                    <p><b>{localeStrings['ScanType']}:</b> {scanType}</p>
                </div>
                {showDownloadReportsButton &&  <DownloadPluginReports /> }
            </div>
            <hr />
            <h1>{localeStrings['Report']}</h1>
            <ViewPluginScanReport />
        </div>
    );
}
