import { Dropdown, TextField } from "@fluentui/react";
import * as React from "react";
import localeStrings from "../../../../assets/localeStrings/english.json";
import { WikiLink } from "../../../configurations/configuration";
import { DeveloperAccountType } from "../../../lib/enums/common-enums";
import { IDeveloperDetailsSubmitProps } from "../../../lib/interfaces/common-interface";
import useDropdown from "../../hooks/useDropdown";
import useInput from "../../hooks/useInputValidation";
import SectionTitleHelperText from "../../view/section-title-helper-text/section-title-helper-text";
import { developerAccountTypeOptions } from "../dropdown-options";
import "./developer-details-submit.scss";

export default function DeveloperDetailsSubmit(props: IDeveloperDetailsSubmitProps): React.ReactElement {
  const developerAccountType = useDropdown(
    props.initialData.developerAccountType,
    developerAccountTypeOptions,
    true,
    localeStrings[`DeveloperAccountErrorText`],
  );

  const privacyPolicy = useInput(props.initialData.privacyPolicyURL, false);
  const developerName = useInput(props.initialData.developerName, true);
  const developerWebsite = useInput(props.initialData.developerWebsite, false);
  const publisherId = useInput(props.initialData.publisherId, false);

  React.useEffect(() => {
    if (developerAccountType.isValidInput) {
      props.setData({
        developerAccountType: developerAccountType.selectedItem as DeveloperAccountType,
        developerName: developerName.value,
        developerWebsite: developerWebsite.value,
        privacyPolicyURL: privacyPolicy.value,
        publisherId: publisherId.value,
      });
    } else {
      props.setData(null);
    }
  }, [developerAccountType.selectedKey, privacyPolicy.value, developerName.value, developerWebsite.value, publisherId.value]);

  return (
    <div className={"developer-details"}>
      <SectionTitleHelperText
        title={localeStrings[`DeveloperDetails_SectionTitle`]}
        helperText={localeStrings[`DeveloperDetails_SectionHelpText`]}
        linkText={localeStrings[`LearnMoreText`]}
        link={WikiLink}
      />
      <div className="content">
        <TextField
          label={localeStrings[`DeveloperDetails_DeveloperName`]}
          disabled={!props.allowEditOnNonEditableField}
          {...developerName}
        />
        <Dropdown
          label={localeStrings[`DeveloperDetails_DeveloperAccountType`]}
          required
          placeholder={localeStrings[`SelectAnOption`]}
          {...developerAccountType}
          disabled={!props.allowEditOnNonEditableField}
        />
        <TextField
          label={localeStrings[`DeveloperDetails_DeveloperWebsite`]}
          disabled={!props.allowEditOnNonEditableField}
          {...developerWebsite}
        />
        <TextField
          label={localeStrings[`DeveloperDetails_DeveloperPrivacyPolicyUrl`]}
          disabled={!!props.initialData.privacyPolicyURL || !props.allowEditOnNonEditableField}
          {...privacyPolicy}
        />
        <TextField
          label={localeStrings[`PublisherId`]}
          disabled={!!props.initialData.publisherId || !props.allowEditOnNonEditableField}
          {...publisherId}
        />
      </div>
    </div>
  );
}
