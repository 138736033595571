import { Spinner, SpinnerSize } from "@fluentui/react";
import * as React from "react";
import ReactJson from "react-json-view";
import { useParams } from "react-router-dom";
import { ExtensionThreatAnalysisLogin } from "../../configurations/configuration";
import { ExtensionThreatSummaryApis } from "../../configurations/url-configs";
import { URLCreator } from "../../lib/url-creator/url-creator";
import GenericErrorScreen from "../error-screens/generic-error-screen";
import { useAxiosGet } from "../hooks/useAxiosGet";
import ViewMaliciousUrls from "./view-malicious-url";

interface IViewPageParams {
    scanId: string;
    scanner: string;
    scanType: string;
}

export default function ViewSummary(): React.ReactElement {
    const { scanId, scanner, scanType } = useParams<IViewPageParams>();
    const { result, loading, error } = useAxiosGet<any>(
        new URLCreator(ExtensionThreatSummaryApis.GetScanReportByScanId)
            .AddParams("scanId", scanId)
            .AddParams("listener", scanner)
            .AddParams("publisher", scanType)
            .GetUrl(),
        ExtensionThreatAnalysisLogin,
    );
    if (loading) {
        return <Spinner size={SpinnerSize.medium} />;
    } else if (error) {
        return <GenericErrorScreen error={error} />;
    }

    return (
        <div>
            <ReactJson enableClipboard={false} displayDataTypes={false} quotesOnKeys={false} src={result} />
            <ViewMaliciousUrls scanType={scanType} scanId={scanId} data={result}></ViewMaliciousUrls>
        </div>
    );
}
