import { AxiosError } from "axios";
import * as React from "react";
import { errorIcon } from "../../icons/error";
import "./generic-error-screen.scss";

export interface IGenericErrorScreenProps {
  error: AxiosError<any>;
}

export default function GenericErrorScreen(props: IGenericErrorScreenProps): React.ReactElement {
  return (
    <div className="error-layout">
      <div className="error">
        {errorIcon()}
        <h4>An error has occurred.</h4>
      </div>
      <p className="error-text">{JSON.stringify(props.error?.message || props.error)}</p>
      {props.error?.stack ? <p className="error-text">{JSON.stringify(props.error.stack)}</p> : null}
    </div>
  );
}
