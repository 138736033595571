import { useAxiosGet } from "../../hooks/useAxiosGet";
import * as React from "react";
import { ExtensionThreatSummaryApis } from "../../../configurations/url-configs";
import { URLCreator } from "../../../lib/url-creator/url-creator";
import { ExtensionThreatAnalysisLogin } from "../../../configurations/configuration";
import GenericErrorScreen from "../../error-screens/generic-error-screen";
import { Spinner, SpinnerSize } from "@fluentui/react";
import { ILatestSubmissionIds } from "../../../lib/interfaces/common-interface";
import { ExtensionInformationBySubmissionId } from "./../extension-info-by-submission-id/extension-info-by-submission-id";
import localeStrings from "../../../../assets/localeStrings/english.json";
import "./extension-info-by-crxid.scss";

interface ExtensionInformationByCrxIdProps {
    crxId: string
}

export function ExtensionInformationByCrxId(props: ExtensionInformationByCrxIdProps): React.ReactElement {
    const { result, loading, error } = useAxiosGet<ILatestSubmissionIds>(
        new URLCreator(ExtensionThreatSummaryApis.GetLatestSubmissionIdByCrxId)
            .AddRouteParam(props.crxId)
            .GetUrl(),
        ExtensionThreatAnalysisLogin,
    );

    if (error) {
        return <GenericErrorScreen error={error} />;
    }

    if (loading) {
        return <Spinner size={SpinnerSize.medium} />
    }
    return (
        <div className="extension-info-crx-outer">
            {result.latestPublished && <ExtensionInformationBySubmissionId
                submissionId={result.latestPublished}
                title={localeStrings[`LatestWFPublishedSubmissionDetails`]}
                subtitle={localeStrings[`LatestSubmissionSubtitle`]} />}
            {result.latestPublished !== result.latest && <ExtensionInformationBySubmissionId
                submissionId={result.latest}
                title={localeStrings[`LatestSubmissionDetails`]}
                subtitle={localeStrings[`LatestSubmissionDetailsSubtitle`]} />}
        </div>
    );
}
