import { Spinner, SpinnerSize } from "@fluentui/react";
import * as React from "react";
import "../../html-constants/styles/html-styles";
import { ExtensionThreatAnalysisLogin } from "../../configurations/configuration";
import { ExtensionThreatSummaryApis } from "../../configurations/url-configs";
import { URLCreator } from "../../lib/url-creator/url-creator";
import GenericErrorScreen from "../error-screens/generic-error-screen";
import { useAxiosGet } from "../hooks/useAxiosGet";

interface IViewRuleProps {
    title: string;
    ruleId: string;
}

export default function RuleSummary(props: IViewRuleProps): React.ReactElement {
    const { result, loading, error } = useAxiosGet<any>(
        new URLCreator(ExtensionThreatSummaryApis.GetRuleDetailsByRuleId).AddParams("ruleId", props.ruleId).GetUrl(),
        ExtensionThreatAnalysisLogin,
    );
    if (loading) {
        return <Spinner size={SpinnerSize.medium} />;
    } else if (error) {
        return <GenericErrorScreen error={error} />;
    }
    return (
        <div>
            <h3>{props.title}</h3>
            <hr />
            <pre className="json-data">
                 {JSON.stringify(result, undefined, 4)}
            </pre>
        </div>
    );
}