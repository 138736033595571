import * as React from 'react';
import localeStrings from '../../../assets/localeStrings/english.json';
import { Dropdown, IDropdownOption, PrimaryButton, Spinner, SpinnerSize, TextField } from '@fluentui/react';
import './product-information.scss';
import useInput from '../hooks/useInputValidation';
import { getProduct, isValidGuid } from '../../lib/utils/util';
import Routes from '../../root/routes';
import { useHistory, useLocation } from 'react-router';
import { useAxiosGet } from '../hooks/useAxiosGet';
import { ExtensionThreatSummaryApis } from '../../configurations/url-configs';
import { URLCreator } from '../../lib/url-creator/url-creator';
import { ExtensionThreatAnalysisLogin } from '../../configurations/configuration';
import useDropdown from '../hooks/useDropdown';
import { IViewAllVersionsResponse } from '../../lib/interfaces/common-interface';
import { Keys, ProductType } from '../../lib/enums/common-enums';
import GenericErrorScreen from '../error-screens/generic-error-screen';
import { AxiosError } from 'axios';

export default function ProductInformation(): React.ReactElement {
    const productTypeDropdownOptions: IDropdownOption[] = [
        { key: Keys.BingChatPlugin, text: Keys.BingChatPlugin },
    ];
    const history = useHistory();
    const productType = getProduct(useLocation().pathname);
    const versionDropDown = useDropdown("", [], true);
    const productIdInputBox = useInput("", true, localeStrings["ProductIdErrorText"], isValidGuid);
    const productTypeDropDown = useDropdown(Keys.BingChatPlugin, productTypeDropdownOptions, true);

    const { result: versions, loading, error } = useAxiosGet<IViewAllVersionsResponse[]>(
        isValidGuid(productIdInputBox.value)
            ? new URLCreator(ExtensionThreatSummaryApis.GetAllVersionsByProductId)
                .AddParams("productId", productIdInputBox.value)
                .GetUrl()
            : null,
        ExtensionThreatAnalysisLogin,
    );

    React.useEffect(() => {
        versionDropDown.updateOptions([]);
        if (versions) {
            const versionsDropdown = versions.map((ele: IViewAllVersionsResponse) => ({
                key: ele.version,
                text: ele.version
            }));
            versionDropDown.updateOptions(versionsDropdown);
        }
    }, [versions, productIdInputBox.value]);

    React.useEffect(() => {
        versionDropDown.updateOptions([]);
    }, [productIdInputBox.value]);

    const search = () => {
        history.push(`/${productType}/${Routes.Information}/productType/${productTypeDropDown.selectedItem}/id/${productIdInputBox.value}/version/${versionDropDown.selectedItem}`);
    }

    if (loading) {
        return <Spinner size={SpinnerSize.medium} />;
    } else if(error.code === AxiosError.ERR_BAD_REQUEST) {
        return <GenericErrorScreen error={error} />;
    }

    return (
        <div>
            <h3 className="title">{localeStrings[`FindProductInformation`].replace('Product', ProductType.MicrosoftCopilotPlugin)}</h3>
            <hr />
            <div className="report-outer-div">
                <h4>{localeStrings[`ProductId`]}</h4>
                <TextField
                    className='productId-textfield'
                    {...productIdInputBox}
                    iconProps={{ iconName: "search" }}
                />
                <h4>{localeStrings[`ProductVersion`]}</h4>
                <Dropdown
                    className='version-dropdown'
                    placeholder={localeStrings[`SelectAnOption`]}
                    {...versionDropDown}
                />
                <h4>{localeStrings[`ProductType`]}</h4>
                <Dropdown
                    className='product-type-dropdown'
                    placeholder={localeStrings[`SelectAnOption`]}
                    {...productTypeDropDown}
                />
                <PrimaryButton onClick={search} disabled={!productIdInputBox.isValidInput || !versionDropDown.isValidInput || !productTypeDropDown.isValidInput}>
                    {localeStrings[`Search`]}
                </PrimaryButton>
            </div>
        </div>
    );
}
