import { useMsal } from "@azure/msal-react";
import { ContextualMenu, IContextualMenuItem, Persona, PersonaSize, Spinner, SpinnerSize } from "@fluentui/react";
import * as React from "react";
import localeStrings from "../../../../assets/localeStrings/english.json";
import { profileLogin } from "../../../configurations/configuration";
import { MsGraphGetProfile } from "../../../configurations/url-configs";
import { Keys } from "../../../lib/enums/common-enums";
import { IGraphUserResponse } from "../../../lib/interfaces/common-interface";
import { useAxiosGet } from "../../hooks/useAxiosGet";
import "./Header.scss";

export default function Header(): React.ReactElement {
  const { result: graphData, loading } = useAxiosGet<IGraphUserResponse>(MsGraphGetProfile, profileLogin);
  const { instance, accounts } = useMsal();
  const linkRef = React.useRef(null);

  const [showMenu, setShowMenu] = React.useState<boolean>(true);
  const menuItems: IContextualMenuItem[] = [
    {
      disabled: true,
      key: Keys.Name,
      text: graphData?.givenName,
    },
    {
      disabled: true,
      key: Keys.Role,
      text: instance.getAccountByHomeId(accounts[0]?.homeAccountId)?.idTokenClaims[`roles`][0],
    },
    {
      key: Keys.SignOut,
      onClick: () => {
        instance.logout();
      },
      text: localeStrings[`SignOut`],
    },
  ];

  return (
    <div className="header">
      <div className="contents">
        <div className="center-align brand">
          <p className="site-name">{localeStrings[`Header`]}</p>
        </div>
        <div className="sign-in center-align">
          {loading ? (
            <Spinner size={SpinnerSize.medium} />
          ) : (
            <>
              <Persona
                ref={linkRef}
                text={graphData.givenName}
                size={PersonaSize.size32}
                hidePersonaDetails
                onClick={() => {
                  setShowMenu(false);
                }}
              />
              <ContextualMenu
                target={linkRef}
                items={menuItems}
                hidden={showMenu}
                onDismiss={() => {
                  setShowMenu(true);
                }}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
}
