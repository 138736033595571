import { IComboBox, IComboBoxOption } from "@fluentui/react";
import * as React from "react";
import localeStrings from "../../../assets/localeStrings/english.json";

export interface IUseComboBoxInput {
  isValidInput: boolean;
  onChange: (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => void;
  selectedKey?: string | number;
  selectedItem?: string;
  errorMessage: string;
  required: boolean;
  options: IComboBoxOption[];
  setOptions: React.Dispatch<React.SetStateAction<IComboBoxOption[]>>;
}

export default function useComboBox(
  defaultSelectedItem: string,
  possibleOptions: IComboBoxOption[],
  required: boolean = false,
  errorHelpText = localeStrings[`GenericDropDownErrorText`],
): IUseComboBoxInput {
  const [selectedKey, setSelectedKey] = React.useState<string | number>(
    possibleOptions.filter((e) => e.text === defaultSelectedItem)[0]?.key,
  );
  const [options, setOptions] = React.useState<IComboBoxOption[]>(possibleOptions);
  const [selectedItem, setSelectedItem] = React.useState<string>(selectedKey ? defaultSelectedItem : null);
  const [isValidInput, setIsValidInput] = React.useState<boolean>(!!selectedKey);
  const [errorMessage, setErrorMessage] = React.useState<string>(errorHelpText);

  React.useEffect(() => {
    const items = options.filter((e) => e.text === defaultSelectedItem)[0];
    setSelectedItem(items?.text);
    setSelectedKey(items?.key as string);
    setIsValidInput(!!items?.text);
    setErrorMessage(!items?.text && required ? errorHelpText : "");
  }, []);

  const onChange = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
    setSelectedKey(option.key);
    setSelectedItem(option.text);
    setIsValidInput(!!option.key);
    setErrorMessage(!option.key && required ? errorHelpText : "");
  };

  return {
    errorMessage,
    isValidInput,
    onChange,
    options,
    required,
    selectedItem,
    selectedKey,
    setOptions
  };
}
