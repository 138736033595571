import { useParams } from "react-router";
import { ExtensionThreatSummaryApis } from "../../configurations/url-configs";
import { URLCreator } from "../../lib/url-creator/url-creator";
import { useAxiosGet } from "../hooks/useAxiosGet";
import { ExtensionThreatAnalysisLogin } from "../../configurations/configuration";
import localeStrings from "../../../assets/localeStrings/english.json"
import GenericErrorScreen from "../error-screens/generic-error-screen";
import { DefaultButton, Spinner, SpinnerSize } from "@fluentui/react";
import * as React from "react";
import { IViewPluginSummaryRouteParams } from "../../lib/interfaces/common-interface";

export default function DownloadPluginReports(): React.ReactElement {
    const { scanId, scanner } = useParams<IViewPluginSummaryRouteParams>();
    const { result, loading, error } = useAxiosGet<any>(
        new URLCreator(ExtensionThreatSummaryApis.GetBlobReportByScanId)
            .AddParams("scanId", scanId)
            .AddParams("listener", scanner)
            .GetUrl(),
        ExtensionThreatAnalysisLogin,
    );
    const downloadResourcesOnClick = (urls) => {
        urls.map((url) => {
            window.open(url)
        })
    }

    if (loading) {
        return <Spinner size={SpinnerSize.medium} />;
    } else if (error) {
        return <GenericErrorScreen error={error} />;
    }
    return (
        <div style={{margin: "auto 0"}}>
            <DefaultButton text={localeStrings['DownloadReports']} onClick={() => downloadResourcesOnClick(result)} title={localeStrings['DownloadReportsTitle']} allowDisabledFocus />
        </div>
    )
}