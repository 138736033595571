import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import * as React from "react";
import {
  IScopesObj,
  IUseAccessToken,
} from "../../lib/interfaces/common-interface";

/**
 * Hook for getting the access token.
 * @returns returns access token or error while retrieving the access token. s
 */
export function useAccessToken(scopes: IScopesObj): IUseAccessToken {
  const { instance, accounts } = useMsal();
  const [accessToken, setAccessToken] = React.useState<string>(null);
  const [error, setError] = React.useState<any>(null);

  React.useEffect(() => {
    if (scopes === null) {
      setAccessToken("");
    } else if (accounts.length > 0) {
      instance
        .acquireTokenSilent({
          account: accounts[0] as any,
          ...scopes,
        })
        .then((resp) => setAccessToken(resp.accessToken))
        .catch((err) => {
          if (err instanceof InteractionRequiredAuthError) {
            instance
              .acquireTokenPopup(scopes)
              .then((resp) => setAccessToken(resp.accessToken))
              .catch((error) => setError(error));
          } else {
            setError(err);
          }
        });
    } else {
      instance
        .acquireTokenPopup(scopes)
        .then((resp) => setAccessToken(resp.accessToken))
        .catch((error) => setError(error));
    }
  }, []);
  return {
    accessToken,
    error,
  };
}
