import * as React from "react";
import localeStrings from "../../../../assets/localeStrings/english.json";
import { WikiLink } from "../../../configurations/configuration";
import { IExtensionAnalysis } from "../../../lib/interfaces/common-interface";
import TextWithReadMore from "../../common-form-componnets/text-with-read-more/text-with-read-more";
import SectionTitleHelperText from "../section-title-helper-text/section-title-helper-text";
import { ShowRules } from "./show-rules/show-rules";

export default function ExtensionAnalysisView(props: IExtensionAnalysis): React.ReactElement {
  return (
    <div className={"threat-analysis"}>
      <SectionTitleHelperText
        title={localeStrings[`ExtensionAnalysis_SectionTitle`]}
        helperText={localeStrings[`ExtensionAnalysis_SectionHelpText`]}
        linkText={localeStrings[`LearnMoreText`]}
        link={WikiLink}
      />
      <div className="content">
        <div>
          <p className="field-title">{localeStrings[`ExtensionAnalysis_Reproducible`]}</p>
          <p className="field-value">{props.isReproducible ? localeStrings[`Yes`] : localeStrings[`No`]}</p>
        </div>
        <div>
          <p className="field-title">{localeStrings[`ExtensionAnalysis_Malicious`]}</p>
          <p className="field-value">{props.isMalicious ? localeStrings[`Yes`] : localeStrings[`No`]}</p>
        </div>
        {props.isMalicious ?
          <>
            <div>
              <p className="field-title">{localeStrings[`ExtensionAnalysis_TTP`]}</p>
              <p className="field-value">{props.ttp}</p>
            </div>
            <div>
              <p className="field-title">{localeStrings[`ExtensionAnalysis_IOCType`]}</p>
              <p className="field-value">{props.iocType}</p>
            </div>
            <div className="text-box">
              <p className="field-title">{localeStrings[`ExtensionAnalysis_IOCValue`]}</p>
              <p className="field-value">{props.iocValue}</p>
            </div>
          </> : ''
        }
        <div>
          <p className="field-title ">{localeStrings[`ExtensionAnalysis_AttackVectorCategory`]}</p>
          {props.attackVectorCategories.map((ele) => (
            <p key={ele} className="field-value" children={ele} />
          ))}
        </div>
        <div>
          <p className="field-title">{localeStrings[`ExtensionAnalysis_Recommendation`]}</p>
          <p className="field-value">{props.recommendation}</p>
        </div>
        <div className="text-box">
            <p className="field-title">{localeStrings[`ExtensionAnalysis_RecommendationReason`]}</p>
            <p className="field-value">{props.recommendationReason}</p>
        </div>
        <div>
          <p className="field-title">{localeStrings[`ExtensionAnalysis_ChromeStoreUrl`]}</p>
          <TextWithReadMore text={props.isActiveInChromeStore ? props.chromeStoreUrl : localeStrings[`NotAvailable`]} />
        </div>
        <div>
          <p className="field-title">{localeStrings[`ExtensionAnalysis_DaasLogUrl`]}</p>
          <TextWithReadMore text={props.isDaasPerformed ? props.daasOutputLogFile : localeStrings[`NotAvailable`]} />
        </div>
        <div>
          <p className="field-title">{localeStrings[`ExtensionAnalysis_MITREAttack`]}</p>
          <p className="field-value">{props.mitreAttack}</p>
        </div>
        <div className="text-box">
          <p className="field-title">{localeStrings[`ExtensionAnalysis_FailedOnPolicy`]}</p>
          <p className="field-value">{props.policyFailure}</p>
        </div>
        <div >
          <p className="field-title">{localeStrings[`RulesDetected`]}</p>
          <ShowRules rules={props.detectedRules} />
        </div>
        <div>
          <p className="field-title">{localeStrings[`RulesReported`]}</p>
          <ShowRules rules={props.reportedRules} />
        </div>
        <div className="text-box">
          <p className="field-title">{localeStrings[`ExtensionAnalysis_Vulnerability`]}</p>
          <TextWithReadMore text={props.vulnerability} />
        </div>
      </div>
      <div className="comment-box">
        <p className="field-title">{localeStrings[`ExtensionAnalysis_Comments`]}</p>
        <TextWithReadMore text={props.comments} />
      </div>
    </div>
  );
}
