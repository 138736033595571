/**
 * Create URL based on string or based on path. No need to specify protocol and host part.
 */
export class URLCreator {
  private urlObj: URL;

  public constructor(url: string, appendHost: boolean = true) {
    this.urlObj = new URL(appendHost ? `${window.location.protocol}/${window.location.host}/${url}` : url);
  }

  public AddParams(paramName: string, paramValue: string): URLCreator {
    this.urlObj.searchParams.append(paramName, encodeURI(paramValue));
    return this;
  }

  public AddRouteParam(routeParam: string): URLCreator {
    this.urlObj.pathname =
      this.urlObj.pathname[this.urlObj.pathname.length - 1] === "/"
        ? `${this.urlObj.pathname}${routeParam}`
        : `${this.urlObj.pathname}/${routeParam}`;
    return this;
  }

  public GetUrl(): string {
    return this.urlObj.href;
  }

  public GetSearch(): string {
    return this.urlObj.search;
  }
}
