import * as FileSaver from "file-saver";
import { Parser } from "json2csv";
import * as React from "react";

export interface IUseJson2Csv {
  error: any;
  loading: boolean;
  saveJsonAsCsv: (data: any, fileName: string) => void;
}
/**
 * Hook for downloading a json file as csv.
 */
export function useSaveJson2Csv(): IUseJson2Csv {
  const [error, setError] = React.useState<any>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const saveJsonAsCsv = (myData: any, fileName: string): void => {
    try {
      setLoading(true);
      const csvData = new Parser().parse(myData);
      const blobOfCsv = new Blob([csvData], { type: "text/csv;charset=utf-8" });
      FileSaver.saveAs(blobOfCsv, fileName);
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };
  return {
    error,
    loading,
    saveJsonAsCsv,
  };
}
