import * as React from 'react';
import GenericErrorScreen from '../../error-screens/generic-error-screen';
import localeStrings from '../../../../assets/localeStrings/english.json';
import Routes from '../../../root/routes';
import { ExtensionThreatAnalysisLogin } from '../../../configurations/configuration';
import { ExtensionThreatSummaryApis } from '../../../configurations/url-configs';
import { IScanStatusResponse } from '../../../lib/interfaces/common-interface';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { ScanStatus } from '../../../lib/enums/common-enums';
import { URLCreator } from '../../../lib/url-creator/url-creator';
import { useAxiosGet } from '../../hooks/useAxiosGet';
import './scan-status-result.scss';
import {
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  SelectionMode,
  Spinner,
  SpinnerSize,
} from "@fluentui/react";
import { getProduct } from '../../../lib/utils/util';

interface IViewPageParams {
  productId: string;
  validationId?: string | null;
}

export default function ScanStatusResult(): React.ReactElement {
  const productType = getProduct(useLocation().pathname);
  const { productId, validationId } = useParams<IViewPageParams>();
  var { result, loading, error } = useAxiosGet<IScanStatusResponse[]>(
    new URLCreator(ExtensionThreatSummaryApis.GetScanStatusByProductIdAndValidationId)
      .AddParams("productId", productId)
      .AddParams("validationId", validationId)
      .GetUrl(),
    ExtensionThreatAnalysisLogin,
  );

  const renderScanIds = (item: any): React.ReactNode => {
    return (<Link to={`/${productType}/${Routes.ScanReport}/${item.scanId}`} style={{textDecoration: 'none'}}>{item.scanId}</Link>);
  };

  const columns: IColumn[] = [
    { key: "scanId", name: "Scan Id", fieldName: "scanId", minWidth: 500, isResizable: true, onRender: renderScanIds },
    { key: "scanStatus", name: "Scan Status", fieldName: "scanStatus", minWidth: 500, isResizable: true },
  ];

  const items =
    result !== null &&
    result.map((item) => ({
      scanId: item.scanId,
      scanStatus: ScanStatus[item.scanStatus],
    }));

  if (loading) {
    return <Spinner size={SpinnerSize.medium} />;
  } else if (error) {
    return <GenericErrorScreen error={error} />;
  }

  return (
    <div>
      <div className="onDemand-scan-status-title">
        <h3>{localeStrings["GetScanStatusTitle"]}</h3>
        {result && ( <h3>{localeStrings["ScanStatusResponsePageTitle"]}{result.length}</h3>)}
      </div>

      <hr/>

      <div>
        <div className="details-div">
          <h3>Fetched Scan Status for</h3>
          <p><strong>{localeStrings["ProductId"]}: </strong> {productId}</p>
           <strong>{localeStrings["ValidationId"]}: </strong> {validationId}
        </div>
      </div>
       
      <div className="table-style-div">
        <DetailsList
          items={items}
          columns={columns}
          layoutMode={DetailsListLayoutMode.fixedColumns}
          selectionPreservedOnEmptyClick={true}
          selectionMode={SelectionMode.none}
        />
      </div>
    </div>
  );
}
